import React from 'react'
import chart from '../../assets/tokenomic/chart.png'
import prop1 from '../../assets/tokenomic/prop1.gif'
import prop2 from '../../assets/tokenomic/prop2.gif'
import prop3 from '../../assets/tokenomic/prop3.gif'
import prop4 from '../../assets/tokenomic/prop4.gif'
function Tokenomic(props)
{
  
  if(props.language==='cn')
    return (
      <>
        <div className='tokenomic' ref={props.tokenomicsRef}>
          <h1>代币经济学</h1>
          <img src={chart} />
          <img src={prop1} id='prop1' className='prop' />
          <img src={prop2} id='prop2' className='prop' />
          <img src={prop3} id='prop3' className='prop' />
          <img src={prop4} id='prop4' className='prop' />
          <h4>初始供应量　10,520,000</h4>
        </div>
      </>
    )

  if(props.language==='jp')
    return (
      <>
        <div className='tokenomic jp' ref={props.tokenomicsRef}>
          <h1>トケノミック</h1>
          <img src={chart} />
          <img src={prop1} id='prop1' className='prop' />
          <img src={prop2} id='prop2' className='prop' />
          <img src={prop3} id='prop3' className='prop' />
          <img src={prop4} id='prop4' className='prop' />
          <h4>原点回転資金 10,520,000</h4>
        </div>
      </>
    )
    if(props.language==='esp')
      return (
        <>
          <div className='tokenomic' ref={props.tokenomicsRef}>
            <h1>Economía de Tokens</h1>
            <img src={chart} />
            <img src={prop1} id='prop1' className='prop' />
            <img src={prop2} id='prop2' className='prop' />
            <img src={prop3} id='prop3' className='prop' />
            <img src={prop4} id='prop4' className='prop' />
            <h4>Circulación inicial del Supply 10,520,000</h4>
          </div>
        </>
      )
    if (props.language === 'pts')
      return (
        <>
          <div className='tokenomic' ref={props.tokenomicsRef}>
            <h1>Economia de Tokens</h1>
            <img src={chart} />
            <img src={prop1} id='prop1' className='prop' />
            <img src={prop2} id='prop2' className='prop' />
            <img src={prop3} id='prop3' className='prop' />
            <img src={prop4} id='prop4' className='prop' />
            <h4>Circulação inicial de Supply 10,520,000</h4>
          </div>
        </>
      )
    else
      return (
        <>
          <div className='tokenomic' ref={props.tokenomicsRef}>
            <h1>TOKENOMIC</h1>
            <img src={chart} />
            <img src={prop1} id='prop1' className='prop' />
            <img src={prop2} id='prop2' className='prop' />
            <img src={prop3} id='prop3' className='prop' />
            <img src={prop4} id='prop4' className='prop' />
            <h4>Initial Circulating Supply 10,520,000</h4>
          </div>
        </>
      )
}

export default Tokenomic