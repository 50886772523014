import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import Dapp from './Dapp'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom'
import Navbar from '../src/Components/Dapp/Navbar'
import Seedfund from '../src/Components/Dapp/Seedfund'
import Privatesale from '../src/Components/Dapp/Privatesale'
import WhitelistPresale from '../src/Components/Dapp/WhitelistPresale'
import Airdrop from '../src/Components/Dapp/Airdrop'
import Footer from '../src/Components/Dapp/Footer'
import PrivateClaim from '../src/Components/Dapp/privateClaim'
import './App.css'


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<App />} />
        <Route exact path='/fishingtown-pad' element={<Dapp />} />
        {/* <Route
          exact
          path='/fishingtown-pad/privatepresale'
          element={
            <div className='app'>
              <Navbar />
              <WhitelistPresale />
              <Footer />
            </div>
          }
        />
        <Route
          exact
          path='/fishingtown-pad/seedfund'
          element={
            <div className='app'>
              <Navbar />
              <Seedfund />
              <Footer />
            </div>
          }
        />
        <Route
          exact
          path='/fishingtown-pad/airdrop'
          element={
            <div className='app'>
              <Navbar />
              <Airdrop />
              <Footer />
            </div>
          }
        />
        <Route
          exact
          path='/fishingtown-pad/privatesale3122021'
          element={
            <div className='app'>
              <Navbar />
              <Privatesale />
              <Footer />
            </div>
          }
        /> */}
        <Route
          exact
          path='/fishingtown-pad/privatepresale'
          element={
            <div className='app'>
              <Navbar />
              <PrivateClaim />
              <Footer />
            </div>
          }
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
