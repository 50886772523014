import React from 'react'
import bnp from '../../assets/partner/bnp.png'
import soon from '../../assets/partner/soon.png'
import cryptovn from '../../assets/partner/cryptovn.png'
import cryptolibrary from '../../assets/partner/cryptolibrary.png'
import excalibur from '../../assets/partner/excalibur.png'
import coinxpad from '../../assets/partner/coinxpad.png'
import nftpad from '../../assets/partner/nftpad.png'
import trustpad from '../../assets/partner/trustpad.png'
import tugoushequ from '../../assets/partner/tugoushequ.png'
import zenism from '../../assets/partner/zenism.png'
import coinlab from '../../assets/partner/coinlab.jpg'
import avg from '../../assets/partner/avg.jpg'
import daolaunch from '../../assets/partner/daolaunch.jpg'
import cryptocup from '../../assets/partner/cryptocup.png'
import bda from '../../assets/partner/bda.jpg'
import dct from '../../assets/partner/dct.jpg'
import avstarcapital from '../../assets/partner/avstarcapital.png'
import investment from '../../assets/partner/investment.png'
import hadow from '../../assets/partner/hadow.jpg'
import x21 from '../../assets/partner/x21.jpg'
import aaa from '../../assets/partner/aaa.png'

export default function partner(props){

  if(props.language==='cn')
    return (
      <>
        <div className='partner cn' ref={props.partnerRef}>
          <h1>伙伴</h1>
          <div className='grid'>
            <a href='https://trustpad.io/' target='_blank'>
              <img src={trustpad} />
            </a>
            <a href='https://nftpad.fi/' target='_blank'>
              <img src={nftpad} />
            </a>
            <a href='https://coinxpad.io/' target='_blank'>
              <img src={coinxpad} />
            </a>
            <a href='http://excaliburcapital.net/' target='_blank'>
              <img src={excalibur} />
            </a>
            <a>
              <img src={cryptolibrary} />
            </a>
            <a
              href='https://blockchainnetworkph.com/?fbclid=IwAR2FS_-qDVmF3A5pQXwwa2bId6rd0FFYUmVDG057FSqKoVmAkQJmMK4aK2s'
              target='_blank'
            >
              <img src={bnp} />
            </a>
            <a href='https://cryptovietnam.vn/' target='_blank'>
              <img src={cryptovn} />
            </a>
            <a href='https://zenism.jp/' target='_blank'>
              <img src={zenism} />
            </a>
            <a href='http://www.tugoushequ.com/' target='_blank'>
              <img src={tugoushequ} />
            </a>
            <a href='https://daolaunch.net/' target='_blank'>
              <img src={daolaunch} />
            </a>
            <a href='https://autoventure.group/' target='_blank'>
              <img src={avg} />
            </a>
            <a
              href='https://coinlab.ventures/?fbclid=IwAR0uvYckon1sdiemeQyzH5lAvyL8RBtmzvU5euAmO1hyz6d6ONQU8J7jGyA/'
              target='_blank'
            >
              <img src={coinlab} />
            </a>
            <a
              href='https://coinlab.ventures/?fbclid=IwAR0uvYckon1sdiemeQyzH5lAvyL8RBtmzvU5euAmO1hyz6d6ONQU8J7jGyA/'
              target='_blank'
            >
              <img src={cryptocup} />
            </a>
            <a
              href='https://coinlab.ventures/?fbclid=IwAR0uvYckon1sdiemeQyzH5lAvyL8RBtmzvU5euAmO1hyz6d6ONQU8J7jGyA/'
              target='_blank'
            >
              <img src={bda} />
            </a>
            <a>
              <img src={x21} />
            </a>
            <a>
              <img src={dct} />
            </a>
            <a href='https://avstarcapital.co/' target='_blank'>
              <img src={avstarcapital} />
            </a>
            <a>
              <img src={investment} />
            </a>
            <a href='https://www.hadow.jp/' target='_blank'>
              <img src={hadow} />
            </a>
            <a href='https://www.facebook.com/OfficialAAA/' target='_blank'>
              <img src={aaa} />
            </a>
          </div>
        </div>
      </>
    )

    if (props.language === 'jp')
      return (
        <>
          <div className='partner jp' ref={props.partnerRef}>
            <h1>パートナー</h1>
            <div className='grid'>
              <a href='https://trustpad.io/' target='_blank'>
                <img src={trustpad} />
              </a>
              <a href='https://nftpad.fi/' target='_blank'>
                <img src={nftpad} />
              </a>
              <a href='https://coinxpad.io/' target='_blank'>
                <img src={coinxpad} />
              </a>
              <a href='http://excaliburcapital.net/' target='_blank'>
                <img src={excalibur} />
              </a>
              <a>
                <img src={cryptolibrary} />
              </a>
              <a
                href='https://blockchainnetworkph.com/?fbclid=IwAR2FS_-qDVmF3A5pQXwwa2bId6rd0FFYUmVDG057FSqKoVmAkQJmMK4aK2s'
                target='_blank'
              >
                <img src={bnp} />
              </a>
              <a href='https://cryptovietnam.vn/' target='_blank'>
                <img src={cryptovn} />
              </a>
              <a href='https://zenism.jp/' target='_blank'>
                <img src={zenism} />
              </a>
              <a href='http://www.tugoushequ.com/' target='_blank'>
                <img src={tugoushequ} />
              </a>
              <a href='https://daolaunch.net/' target='_blank'>
                <img src={daolaunch} />
              </a>
              <a href='https://autoventure.group/' target='_blank'>
                <img src={avg} />
              </a>
              <a
                href='https://coinlab.ventures/?fbclid=IwAR0uvYckon1sdiemeQyzH5lAvyL8RBtmzvU5euAmO1hyz6d6ONQU8J7jGyA/'
                target='_blank'
              >
                <img src={coinlab} />
              </a>
              <a
                href='https://coinlab.ventures/?fbclid=IwAR0uvYckon1sdiemeQyzH5lAvyL8RBtmzvU5euAmO1hyz6d6ONQU8J7jGyA/'
                target='_blank'
              >
                <img src={cryptocup} />
              </a>
              <a
                href='https://coinlab.ventures/?fbclid=IwAR0uvYckon1sdiemeQyzH5lAvyL8RBtmzvU5euAmO1hyz6d6ONQU8J7jGyA/'
                target='_blank'
              >
                <img src={bda} />
              </a>
              <a>
                <img src={x21} />
              </a>
              <a>
                <img src={dct} />
              </a>
              <a href='https://avstarcapital.co/' target='_blank'>
                <img src={avstarcapital} />
              </a>
              <a>
                <img src={investment} />
              </a>
              <a href='https://www.hadow.jp/' target='_blank'>
                <img src={hadow} />
              </a>
              <a href='https://www.facebook.com/OfficialAAA/' target='_blank'>
                <img src={aaa} />
              </a>
            </div>
          </div>
        </>
      )

      if (props.language === 'esp')
        return (
          <>
            <div className='partner' ref={props.partnerRef}>
              <h1>Patrocinadores</h1>
              <div className='grid'>
                <a href='https://trustpad.io/' target='_blank'>
                  <img src={trustpad} />
                </a>
                <a href='https://nftpad.fi/' target='_blank'>
                  <img src={nftpad} />
                </a>
                <a href='https://coinxpad.io/' target='_blank'>
                  <img src={coinxpad} />
                </a>
                <a href='http://excaliburcapital.net/' target='_blank'>
                  <img src={excalibur} />
                </a>
                <a>
                  <img src={cryptolibrary} />
                </a>
                <a
                  href='https://blockchainnetworkph.com/?fbclid=IwAR2FS_-qDVmF3A5pQXwwa2bId6rd0FFYUmVDG057FSqKoVmAkQJmMK4aK2s'
                  target='_blank'
                >
                  <img src={bnp} />
                </a>
                <a href='https://cryptovietnam.vn/' target='_blank'>
                  <img src={cryptovn} />
                </a>
                <a href='https://zenism.jp/' target='_blank'>
                  <img src={zenism} />
                </a>
                <a href='http://www.tugoushequ.com/' target='_blank'>
                  <img src={tugoushequ} />
                </a>
                <a href='https://daolaunch.net/' target='_blank'>
                  <img src={daolaunch} />
                </a>
                <a href='https://autoventure.group/' target='_blank'>
                  <img src={avg} />
                </a>
                <a
                  href='https://coinlab.ventures/?fbclid=IwAR0uvYckon1sdiemeQyzH5lAvyL8RBtmzvU5euAmO1hyz6d6ONQU8J7jGyA/'
                  target='_blank'
                >
                  <img src={coinlab} />
                </a>
                <a
                  href='https://coinlab.ventures/?fbclid=IwAR0uvYckon1sdiemeQyzH5lAvyL8RBtmzvU5euAmO1hyz6d6ONQU8J7jGyA/'
                  target='_blank'
                >
                  <img src={cryptocup} />
                </a>
                <a
                  href='https://coinlab.ventures/?fbclid=IwAR0uvYckon1sdiemeQyzH5lAvyL8RBtmzvU5euAmO1hyz6d6ONQU8J7jGyA/'
                  target='_blank'
                >
                  <img src={bda} />
                </a>
                <a>
                  <img src={x21} />
                </a>
                <a>
                  <img src={dct} />
                </a>
                <a href='https://avstarcapital.co/' target='_blank'>
                  <img src={avstarcapital} />
                </a>
                <a>
                  <img src={investment} />
                </a>
                <a href='https://www.hadow.jp/' target='_blank'>
                  <img src={hadow} />
                </a>
                <a href='https://www.facebook.com/OfficialAAA/' target='_blank'>
                  <img src={aaa} />
                </a>
              </div>
            </div>
          </>
        )


      if (props.language === 'pts')
        return (
          <>
            <div className='partner' ref={props.partnerRef}>
              <h1>Parceiros</h1>
              <div className='grid'>
                <a href='https://trustpad.io/' target='_blank'>
                  <img src={trustpad} />
                </a>
                <a href='https://nftpad.fi/' target='_blank'>
                  <img src={nftpad} />
                </a>
                <a href='https://coinxpad.io/' target='_blank'>
                  <img src={coinxpad} />
                </a>
                <a href='http://excaliburcapital.net/' target='_blank'>
                  <img src={excalibur} />
                </a>
                <a>
                  <img src={cryptolibrary} />
                </a>
                <a
                  href='https://blockchainnetworkph.com/?fbclid=IwAR2FS_-qDVmF3A5pQXwwa2bId6rd0FFYUmVDG057FSqKoVmAkQJmMK4aK2s'
                  target='_blank'
                >
                  <img src={bnp} />
                </a>
                <a href='https://cryptovietnam.vn/' target='_blank'>
                  <img src={cryptovn} />
                </a>
                <a href='https://zenism.jp/' target='_blank'>
                  <img src={zenism} />
                </a>
                <a href='http://www.tugoushequ.com/' target='_blank'>
                  <img src={tugoushequ} />
                </a>
                <a href='https://daolaunch.net/' target='_blank'>
                  <img src={daolaunch} />
                </a>
                <a href='https://autoventure.group/' target='_blank'>
                  <img src={avg} />
                </a>
                <a
                  href='https://coinlab.ventures/?fbclid=IwAR0uvYckon1sdiemeQyzH5lAvyL8RBtmzvU5euAmO1hyz6d6ONQU8J7jGyA/'
                  target='_blank'
                >
                  <img src={coinlab} />
                </a>
                <a
                  href='https://coinlab.ventures/?fbclid=IwAR0uvYckon1sdiemeQyzH5lAvyL8RBtmzvU5euAmO1hyz6d6ONQU8J7jGyA/'
                  target='_blank'
                >
                  <img src={cryptocup} />
                </a>
                <a
                  href='https://coinlab.ventures/?fbclid=IwAR0uvYckon1sdiemeQyzH5lAvyL8RBtmzvU5euAmO1hyz6d6ONQU8J7jGyA/'
                  target='_blank'
                >
                  <img src={bda} />
                </a>
                <a>
                  <img src={x21} />
                </a>
                <a>
                  <img src={dct} />
                </a>
                <a href='https://avstarcapital.co/' target='_blank'>
                  <img src={avstarcapital} />
                </a>
                <a>
                  <img src={investment} />
                </a>
                <a href='https://www.hadow.jp/' target='_blank'>
                  <img src={hadow} />
                </a>
                <a href='https://www.facebook.com/OfficialAAA/' target='_blank'>
                  <img src={aaa} />
                </a>
              </div>
            </div>
          </>
        )
      else
        return (
          <>
            <div className='partner' ref={props.partnerRef}>
              <h1>PARTNER</h1>
              <div className='grid'>
                <a href='https://trustpad.io/' target='_blank'>
                  <img src={trustpad} />
                </a>
                <a href='https://nftpad.fi/' target='_blank'>
                  <img src={nftpad} />
                </a>
                <a href='https://coinxpad.io/' target='_blank'>
                  <img src={coinxpad} />
                </a>
                <a href='http://excaliburcapital.net/' target='_blank'>
                  <img src={excalibur} />
                </a>
                <a>
                  <img src={cryptolibrary} />
                </a>
                <a
                  href='https://blockchainnetworkph.com/?fbclid=IwAR2FS_-qDVmF3A5pQXwwa2bId6rd0FFYUmVDG057FSqKoVmAkQJmMK4aK2s'
                  target='_blank'
                >
                  <img src={bnp} />
                </a>
                <a href='https://cryptovietnam.vn/' target='_blank'>
                  <img src={cryptovn} />
                </a>
                <a href='https://zenism.jp/' target='_blank'>
                  <img src={zenism} />
                </a>
                <a href='http://www.tugoushequ.com/' target='_blank'>
                  <img src={tugoushequ} />
                </a>
                <a href='https://daolaunch.net/' target='_blank'>
                  <img src={daolaunch} />
                </a>
                <a href='https://autoventure.group/' target='_blank'>
                  <img src={avg} />
                </a>
                <a
                  href='https://coinlab.ventures/?fbclid=IwAR0uvYckon1sdiemeQyzH5lAvyL8RBtmzvU5euAmO1hyz6d6ONQU8J7jGyA/'
                  target='_blank'
                >
                  <img src={coinlab} />
                </a>
                <a
                  href='https://coinlab.ventures/?fbclid=IwAR0uvYckon1sdiemeQyzH5lAvyL8RBtmzvU5euAmO1hyz6d6ONQU8J7jGyA/'
                  target='_blank'
                >
                  <img src={cryptocup} />
                </a>
                <a
                  href='https://coinlab.ventures/?fbclid=IwAR0uvYckon1sdiemeQyzH5lAvyL8RBtmzvU5euAmO1hyz6d6ONQU8J7jGyA/'
                  target='_blank'
                >
                  <img src={bda} />
                </a>
                <a>
                  <img src={x21} />
                </a>
                <a>
                  <img src={dct} />
                </a>
                <a href='https://avstarcapital.co/' target='_blank'>
                  <img src={avstarcapital} />
                </a>
                <a>
                  <img src={investment} />
                </a>
                <a href='https://www.hadow.jp/' target='_blank'>
                  <img src={hadow} />
                </a>
                <a href='https://www.facebook.com/OfficialAAA/' target='_blank'>
                  <img src={aaa} />
                </a>
              </div>
            </div>
          </>
        )
} 