import React from 'react' 
import certik from '../../assets/audit/certik.png'
import inspex from '../../assets/audit/inspex.png'
import pekshield from '../../assets/audit/pekshield.jpg'
export default function footer(){

    return (<>
        <div className="dapp-footer">
            <h1>AUDITED BY......</h1>
            <div className='flex'>
                <img src={certik}/>
                <h2>CERTIK</h2>
            </div>
            <div className='flex'>
                <img src={inspex}/>
                <h2>INSPEX</h2>
            </div>
            <div className='flex'>
                <img src={pekshield}/>
                <h2>PEKSHIELD</h2>
            </div>
        </div>
        </>)
}