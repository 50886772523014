import React, { useState ,useEffect } from 'react'
import logo from '../../assets/nav/logo.png'
import hamburger from '../../assets/nav/hamburger.png'
import close from '../../assets/nav/close.png'
import caret from '../../assets/nav/caret.png'
import caret2 from '../../assets/nav/caret2.png'
function Navbar(props) {
  const [isOpen, setIsOpen] = useState(false)
  const [showLanguage,setShowLanguage] = useState(false)
  // const language = ['eng','cn','jp']
  const language = ['eng','cn','jp','esp','pts']

  if (props.language === 'cn') {
    return (
      <>
        <nav className='nav _04b cn-nav'>
          <img src={logo} onClick={props.scrollToHome} id='logo' />
          <div className='nav-menu '>
            <ul className='cn-nav'>
              <li onClick={props.scrollToHome}>首页</li>
              <li onClick={props.scrollToFeatures}>功能</li>

              <li onClick={props.scrollToNFT}>NFTs 物品</li>
              <li onClick={props.scrollToTokenomics}>代币经济学</li>
              <li onClick={props.scrollToRoadmap}>路途线</li>

              <li className='dropdown1'>
                文件 <img src={caret} id='caret' />
                <div className='dropdown2'>
                  <a
                    href='https://fishingtown.io/assets/whitepaper.pdf'
                    target='_blank'
                  >
                    <li>白皮书</li>
                  </a>
                  <a
                    href='https://fishingtown.io/assets/pitchdeck.pdf'
                    target='_blank'
                  >
                    <li>懒人包</li>
                  </a>
                  <a
                    href='https://fishing-town-official.gitbook.io/fishing-town-whitepaper/'
                    target='_blank'
                  >
                    <li>GITBOOK</li>
                  </a>
                </div>
              </li>

              <li>
                <a href='https://market.fishingtown.io/'>市場</a>
              </li>

              <li className='dropdown1'>
                其他 <img src={caret} id='caret' />
                <div className='dropdown2'>
                  <li onClick={props.scrollToStatistics}>统计</li>
                  <li onClick={props.scrollToMint}>铸造和燃烧</li>
                  <li onClick={props.scrollToPartner}>伙伴</li>
                  <li onClick={props.scrollToAudit}>审计</li>
                  <li onClick={props.scrollToTeam}>团队</li>
                </div>
              </li>
              {/* <li className='language'> */}
              <li
                className='language'
                onClick={() => setShowLanguage((showLanguage) => !showLanguage)}
              >
                {props.language.toUpperCase()}
                <img src={caret} id='caret' />
              </li>
              {showLanguage === false ? null : (
                <ul className='language-dropdown'>
                  {language.map((lng) => (
                    <li
                      className='language'
                      onClick={() => {
                        props.setLanguage(lng)
                        setShowLanguage(false)
                      }}
                    >
                      {lng.toUpperCase()}
                    </li>
                  ))}
                </ul>
              )}
              {/* </li> */}
            </ul>
          </div>
        </nav>
        <nav className='mobile-nav'>
          <img src={logo} onClick={props.scrollToHome} />
          <ul>
            <li
              className='language'
              onClick={() => setShowLanguage((showLanguage) => !showLanguage)}
            >
              {props.language.toUpperCase()}
              <img src={caret} id='caret' />
            </li>
            {showLanguage === false ? null : (
              <ul className='language-dropdown'>
                {language.map((lng) => (
                  <li
                    className='language'
                    onClick={() => {
                      props.setLanguage(lng)
                      setShowLanguage(false)
                    }}
                  >
                    {lng.toUpperCase()}
                  </li>
                ))}
              </ul>
            )}
          </ul>
          <img
            src={hamburger}
            id='hamburger'
            onClick={() => setIsOpen((prev) => !prev)}
          />
        </nav>
        {isOpen ? (
          <div className='mobile-nav-menu open'>
            <img src={close} id='close' onClick={() => setIsOpen(false)} />

            <ul>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToFeatures()
                }}
              >
                功能
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToStatistics()
                }}
              >
                统计
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToNFT()
                }}
              >
                NFTs 物品
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToTokenomics()
                }}
              >
                代币经济学
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToRoadmap()
                }}
              >
                路线图
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToMint()
                }}
              >
                铸造和燃烧
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToPartner()
                }}
              >
                伙伴
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToAudit()
                }}
              >
                审计
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToTeam()
                }}
              >
                团队
              </li>
              <a
                href='https://fishingtown.io/assets/whitepaper.pdf'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  白皮书
                </li>
              </a>
              <a
                href='https://fishingtown.io/assets/pitchdeck.pdf'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  懒人包
                </li>
              </a>
              <a
                href='https://fishing-town-official.gitbook.io/fishing-town-whitepaper/'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  GITBOOK
                </li>
              </a>
            </ul>
          </div>
        ) : (
          <div className='mobile-nav-menu'>
            <img src={close} id='close' onClick={() => setIsOpen(false)} />

            <ul>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToFeatures()
                }}
              >
                功能
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToStatistics()
                }}
              >
                统计
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToNFT()
                }}
              >
                NFTs 物品
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToTokenomics()
                }}
              >
                代币经济学
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToRoadmap()
                }}
              >
                路线图
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToMint()
                }}
              >
                铸造和燃烧
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToPartner()
                }}
              >
                伙伴
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToAudit()
                }}
              >
                审计
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToTeam()
                }}
              >
                团队
              </li>
              <a
                href='https://fishingtown.io/assets/whitepaper.pdf'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  白皮书
                </li>
              </a>
              <a
                href='https://fishingtown.io/assets/pitchdeck.pdf'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  懒人包
                </li>
              </a>
              <a
                href='https://fishing-town-official.gitbook.io/fishing-town-whitepaper/'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  GITBOOK
                </li>
              </a>
            </ul>
          </div>
        )}
      </>
    )
  } 
  
  if (props.language === 'jp') {
    return (
      <>
        <nav className='nav _04b'>
          <img src={logo} onClick={props.scrollToHome} id='logo' />
          <div className='nav-menu'>
            <ul>
              <li onClick={props.scrollToHome}>ホーム</li>
              <li onClick={props.scrollToFeatures}>フィーチャー</li>

              <li onClick={props.scrollToNFT}>NFTs アイテム</li>
              <li onClick={props.scrollToTokenomics}>トケノミック</li>
              <li onClick={props.scrollToRoadmap}>ロードマップ</li>

              <li className='dropdown1'>
                ペーパー <img src={caret} id='caret' />
                <div className='dropdown2'>
                  <a
                    href='https://fishingtown.io/assets/whitepaper.pdf'
                    target='_blank'
                  >
                    <li>ホワイトペーパー</li>
                  </a>
                  <a
                    href='https://fishingtown.io/assets/pitchdeck.pdf'
                    target='_blank'
                  >
                    <li>ピッチデッキ</li>
                  </a>
                  <a
                    href='https://fishing-town-official.gitbook.io/fishing-town-whitepaper/'
                    target='_blank'
                  >
                    <li>ギットブック</li>
                  </a>
                </div>
              </li>
              <li>
                <a href='https://market.fishingtown.io/'>マーケットプレイス</a>
              </li>

              <li className='dropdown1'>
                その他 <img src={caret} id='caret' />
                <div className='dropdown2'>
                  <li onClick={props.scrollToStatistics}>統計</li>
                  <li onClick={props.scrollToMint}>ミント及びブバーンズ</li>
                  <li onClick={props.scrollToPartner}>パートナー</li>
                  <li onClick={props.scrollToAudit}>監査</li>
                  <li onClick={props.scrollToTeam}>チーム</li>
                </div>
              </li>
              <li
                className='language'
                onClick={() => setShowLanguage((showLanguage) => !showLanguage)}
              >
                {props.language.toUpperCase()}
                <img src={caret} id='caret' />
              </li>
              {showLanguage === false ? null : (
                <ul className='language-dropdown'>
                  {language.map((lng) => (
                    <li
                      className='language'
                      onClick={() => {
                        props.setLanguage(lng)
                        setShowLanguage(false)
                      }}
                    >
                      {lng.toUpperCase()}
                    </li>
                  ))}
                </ul>
              )}
              {/* </li> */}
            </ul>
          </div>
        </nav>
        <nav className='mobile-nav'>
          <img src={logo} onClick={props.scrollToHome} />
          <ul>
            <li
              className='language'
              onClick={() => setShowLanguage((showLanguage) => !showLanguage)}
            >
              {props.language.toUpperCase()}
              <img src={caret} id='caret' />
            </li>
            {showLanguage === false ? null : (
              <ul className='language-dropdown'>
                {language.map((lng) => (
                  <li
                    className='language'
                    onClick={() => {
                      props.setLanguage(lng)
                      setShowLanguage(false)
                    }}
                  >
                    {lng.toUpperCase()}
                  </li>
                ))}
              </ul>
            )}
          </ul>
          <img
            src={hamburger}
            id='hamburger'
            onClick={() => setIsOpen((prev) => !prev)}
          />
        </nav>
        {isOpen ? (
          <div className='mobile-nav-menu open'>
            <img src={close} id='close' onClick={() => setIsOpen(false)} />

            <ul>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToFeatures()
                }}
              >
                フィーチャー
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToStatistics()
                }}
              >
                統計
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToNFT()
                }}
              >
                NFTs アイテム
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToTokenomics()
                }}
              >
                トケノミック
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToRoadmap()
                }}
              >
                ロードマップ
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToMint()
                }}
              >
                ミントと焼くこと
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToPartner()
                }}
              >
                パートナー
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToAudit()
                }}
              >
                監査
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToTeam()
                }}
              >
                チーム
              </li>
              <a
                href='https://fishingtown.io/assets/whitepaper.pdf'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  ホワイトペーパー
                </li>
              </a>
              <a
                href='https://fishingtown.io/assets/pitchdeck.pdf'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  ピッチデッキ
                </li>
              </a>
              <a
                href='https://fishing-town-official.gitbook.io/fishing-town-whitepaper/'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  GITBOOK
                </li>
              </a>
            </ul>
          </div>
        ) : (
          <div className='mobile-nav-menu'>
            <img src={close} id='close' onClick={() => setIsOpen(false)} />

            <ul>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToFeatures()
                }}
              >
                フィーチャー
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToStatistics()
                }}
              >
                統計
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToNFT()
                }}
              >
                NFTs アイテム
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToTokenomics()
                }}
              >
                トケノミック
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToRoadmap()
                }}
              >
                ロードマップ
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToMint()
                }}
              >
                ミントと焼くこと
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToPartner()
                }}
              >
                パートナー
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToAudit()
                }}
              >
                監査
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToTeam()
                }}
              >
                チーム
              </li>
              <a
                href='https://fishingtown.io/assets/whitepaper.pdf'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  ホワイトペーパー
                </li>
              </a>
              <a
                href='https://fishingtown.io/assets/pitchdeck.pdf'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  ピッチデッキ
                </li>
              </a>
              <a
                href='https://fishing-town-official.gitbook.io/fishing-town-whitepaper/'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  GITBOOK
                </li>
              </a>
            </ul>
          </div>
        )}
      </>
    )
  }
  if(props.language=='esp'){
    return (
      <>
        <nav className='nav _04b esp'>
          <img src={logo} onClick={props.scrollToHome} id='logo' />
          <div className='nav-menu'>
            <ul>
              <li onClick={props.scrollToFeatures}>Características</li>

              <li onClick={props.scrollToNFT}>Objetos NFT</li>
              <li onClick={props.scrollToTokenomics}>Economía de Tokens</li>
              <li onClick={props.scrollToRoadmap}>Mapa de ruta</li>

              <li className='dropdown1'>
                PAPERS <img src={caret} id='caret' />
                <div className='dropdown2'>
                  <a>
                    <li className='whitepaper'>
                      Papel Blanco <img src={caret2} id='caret2' />
                      <div className='dropdown3'>
                        <a
                          href='https://fishingtown.io/assets/whitepaper.pdf'
                          target='_blank'
                        >
                          <li>English Version</li>
                        </a>
                        <a
                          href='https://fishingtown.io/assets/whitepaper_por.pdf'
                          target='_blank'
                        >
                          <li>Portuguese Version</li>
                        </a>
                      </div>
                    </li>
                  </a>
                  <a
                    href='https://fishingtown.io/assets/pitchdeck.pdf'
                    target='_blank'
                  >
                    <li>Plataforma de lanzamiento</li>
                  </a>
                  <a
                    href='https://fishing-town-official.gitbook.io/fishing-town-whitepaper/'
                    target='_blank'
                  >
                    <li>GITBOOK</li>
                  </a>
                </div>
              </li>
              <li id='marketplace'>
                <a href='https://market.fishingtown.io/'>MARKETPLACE</a>
              </li>

              <li className='dropdown1'>
                MORE <img src={caret} id='caret' />
                <div className='dropdown2'>
                  <li onClick={props.scrollToStatistics}>STATISTICS</li>
                  <li onClick={props.scrollToMint}>MINT AND BURN</li>
                  <li onClick={props.scrollToPartner}>PARTNER</li>
                  <li onClick={props.scrollToAudit}>AUDITED</li>
                  <li onClick={props.scrollToTeam}>TEAM</li>
                </div>
              </li>
              {/* <li className='language'> */}
              <li
                className='language'
                onClick={() => setShowLanguage((showLanguage) => !showLanguage)}
              >
                {props.language.toUpperCase()}
                <img src={caret} id='caret' />
              </li>
              {showLanguage === false ? null : (
                <ul className='language-dropdown'>
                  {language.map((lng) => (
                    <li
                      className='language'
                      onClick={() => {
                        props.setLanguage(lng)
                        setShowLanguage(false)
                      }}
                    >
                      {lng.toUpperCase()}
                    </li>
                  ))}
                </ul>
              )}
              {/* </li> */}
            </ul>
          </div>
        </nav>
        <nav className='mobile-nav'>
          <img src={logo} onClick={props.scrollToHome} />
          <ul>
            <li
              className='language'
              onClick={() => setShowLanguage((showLanguage) => !showLanguage)}
            >
              {props.language.toUpperCase()}
              <img src={caret} id='caret' />
            </li>
            {showLanguage === false ? null : (
              <ul className='language-dropdown'>
                {language.map((lng) => (
                  <li
                    className='language'
                    onClick={() => {
                      props.setLanguage(lng)
                      setShowLanguage(false)
                    }}
                  >
                    {lng.toUpperCase()}
                  </li>
                ))}
              </ul>
            )}
          </ul>
          <img
            src={hamburger}
            id='hamburger'
            onClick={() => setIsOpen((prev) => !prev)}
          />
        </nav>
        {isOpen ? (
          <div className='mobile-nav-menu open'>
            <img src={close} id='close' onClick={() => setIsOpen(false)} />

            <ul>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToFeatures()
                }}
              >
                Características
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToStatistics()
                }}
              >
                Estadísticas
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToNFT()
                }}
              >
                Objetos NFT
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToTokenomics()
                }}
              >
                Economía de Tokens
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToRoadmap()
                }}
              >
                Mapa de ruta
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToMint()
                }}
              >
                Minteo y quema
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToPartner()
                }}
              >
                Patrocinadores
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToAudit()
                }}
              >
                Auditorías
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToTeam()
                }}
              >
                Equipo
              </li>
              <a
                href='https://fishingtown.io/assets/whitepaper.pdf'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  Papel Blanco
                </li>
              </a>
              <a
                href='https://fishingtown.io/assets/pitchdeck.pdf'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  Plataforma de lanzamiento
                </li>
              </a>
              <a
                href='https://fishing-town-official.gitbook.io/fishing-town-whitepaper/'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  GITBOOK
                </li>
              </a>
            </ul>
          </div>
        ) : (
          <div className='mobile-nav-menu'>
            <img src={close} id='close' onClick={() => setIsOpen(false)} />

            <ul>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToFeatures()
                }}
              >
                FEATURES
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToStatistics()
                }}
              >
                STATISTICS
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToNFT()
                }}
              >
                NFTs ITEM
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToTokenomics()
                }}
              >
                TOKENOMIC
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToRoadmap()
                }}
              >
                ROADMAP
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToMint()
                }}
              >
                MINT AND BURN
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToPartner()
                }}
              >
                PARTNER
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToAudit()
                }}
              >
                AUDITED
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToTeam()
                }}
              >
                TEAM
              </li>
              <a
                href='https://fishingtown.io/assets/whitepaper.pdf'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  WHITE PAPER
                </li>
              </a>
              <a
                href='https://fishingtown.io/assets/pitchdeck.pdf'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  PITCH DECK
                </li>
              </a>
              <a
                href='https://fishing-town-official.gitbook.io/fishing-town-whitepaper/'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  GITBOOK
                </li>
              </a>
            </ul>
          </div>
        )}
      </>
    )
  }

  if(props.language=='pts'){
    return (
      <>
        <nav className='nav _04b'>
          <img src={logo} onClick={props.scrollToHome} id='logo' />
          <div className='nav-menu'>
            <ul>
              <li onClick={props.scrollToHome}>HOME</li>
              <li onClick={props.scrollToFeatures}>Características</li>

              <li onClick={props.scrollToNFT}>Items NFT</li>
              <li onClick={props.scrollToTokenomics}>Tokenomics</li>
              <li onClick={props.scrollToRoadmap}>ROADMAP</li>

              <li className='dropdown1'>
                PAPERS <img src={caret} id='caret' />
                <div className='dropdown2'>
                  <a>
                    <li className='whitepaper'>
                      Whitepaper <img src={caret2} id='caret2' />
                      <div className='dropdown3'>
                        <a
                          href='https://fishingtown.io/assets/whitepaper.pdf'
                          target='_blank'
                        >
                          <li>English Version</li>
                        </a>
                        <a
                          href='https://fishingtown.io/assets/whitepaper_por.pdf'
                          target='_blank'
                        >
                          <li>Portuguese Version</li>
                        </a>
                      </div>
                    </li>
                  </a>
                  <a
                    href='https://fishingtown.io/assets/pitchdeck.pdf'
                    target='_blank'
                  >
                    <li>Plataforma de lançamento</li>
                  </a>
                  <a
                    href='https://fishing-town-official.gitbook.io/fishing-town-whitepaper/'
                    target='_blank'
                  >
                    <li>Gitbook</li>
                  </a>
                </div>
              </li>
              <li id='marketplace'>
                <a href='https://market.fishingtown.io/'>MARKETPLACE</a>
              </li>

              <li className='dropdown1'>
                MORE <img src={caret} id='caret' />
                <div className='dropdown2'>
                  <li onClick={props.scrollToStatistics}>Estatisticas</li>
                  <li onClick={props.scrollToMint}>Mintar E Queima</li>
                  <li onClick={props.scrollToPartner}>Parceiros</li>
                  <li onClick={props.scrollToAudit}>Auditorías</li>
                  <li onClick={props.scrollToTeam}>Time</li>
                </div>
              </li>
              {/* <li className='language'> */}
              <li
                className='language'
                onClick={() => setShowLanguage((showLanguage) => !showLanguage)}
              >
                {props.language.toUpperCase()}
                <img src={caret} id='caret' />
              </li>
              {showLanguage === false ? null : (
                <ul className='language-dropdown'>
                  {language.map((lng) => (
                    <li
                      className='language'
                      onClick={() => {
                        props.setLanguage(lng)
                        setShowLanguage(false)
                      }}
                    >
                      {lng.toUpperCase()}
                    </li>
                  ))}
                </ul>
              )}
              {/* </li> */}
            </ul>
          </div>
        </nav>
        <nav className='mobile-nav'>
          <img src={logo} onClick={props.scrollToHome} />
          <ul>
            <li
              className='language'
              onClick={() => setShowLanguage((showLanguage) => !showLanguage)}
            >
              {props.language.toUpperCase()}
              <img src={caret} id='caret' />
            </li>
            {showLanguage === false ? null : (
              <ul className='language-dropdown'>
                {language.map((lng) => (
                  <li
                    className='language'
                    onClick={() => {
                      props.setLanguage(lng)
                      setShowLanguage(false)
                    }}
                  >
                    {lng.toUpperCase()}
                  </li>
                ))}
              </ul>
            )}
          </ul>
          <img
            src={hamburger}
            id='hamburger'
            onClick={() => setIsOpen((prev) => !prev)}
          />
        </nav>
        {isOpen ? (
          <div className='mobile-nav-menu open'>
            <img src={close} id='close' onClick={() => setIsOpen(false)} />

            <ul>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToFeatures()
                }}
              >
                Características
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToStatistics()
                }}
              >
                Estatisticas
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToNFT()
                }}
              >
                Items NFT
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToTokenomics()
                }}
              >
                Tokenomics
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToRoadmap()
                }}
              >
                Roadmap
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToMint()
                }}
              >
                Mintar E Queima
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToPartner()
                }}
              >
                Parceiros
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToAudit()
                }}
              >
                Auditorías
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToTeam()
                }}
              >
                Time
              </li>
              <a
                href='https://fishingtown.io/assets/whitepaper.pdf'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  Whitepaper
                </li>
              </a>
              <a
                href='https://fishingtown.io/assets/pitchdeck.pdf'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  Plataforma de lançamento
                </li>
              </a>
              <a
                href='https://fishing-town-official.gitbook.io/fishing-town-whitepaper/'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  Gitbook
                </li>
              </a>
            </ul>
          </div>
        ) : (
          <div className='mobile-nav-menu'>
            <img src={close} id='close' onClick={() => setIsOpen(false)} />

            <ul>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToFeatures()
                }}
              >
                FEATURES
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToStatistics()
                }}
              >
                STATISTICS
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToNFT()
                }}
              >
                NFTs ITEM
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToTokenomics()
                }}
              >
                TOKENOMIC
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToRoadmap()
                }}
              >
                ROADMAP
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToMint()
                }}
              >
                MINT AND BURN
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToPartner()
                }}
              >
                PARTNER
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToAudit()
                }}
              >
                AUDITED
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToTeam()
                }}
              >
                TEAM
              </li>
              <a
                href='https://fishingtown.io/assets/whitepaper.pdf'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  WHITE PAPER
                </li>
              </a>
              <a
                href='https://fishingtown.io/assets/pitchdeck.pdf'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  PITCH DECK
                </li>
              </a>
              <a
                href='https://fishing-town-official.gitbook.io/fishing-town-whitepaper/'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  GITBOOK
                </li>
              </a>
            </ul>
          </div>
        )}
      </>
    )
  }

  else{
    return (
      <>
        <nav className='nav _04b'>
          <img src={logo} onClick={props.scrollToHome} id='logo' />
          <div className='nav-menu'>
            <ul>
              <li onClick={props.scrollToHome}>HOME</li>
              <li onClick={props.scrollToFeatures}>FEATURES</li>

              <li onClick={props.scrollToNFT}>NFTs ITEM</li>
              <li onClick={props.scrollToTokenomics}>TOKENOMIC</li>
              <li onClick={props.scrollToRoadmap}>ROADMAP</li>

              <li className='dropdown1'>
                PAPERS <img src={caret} id='caret' />
                <div className='dropdown2'>
                  <a>
                    <li className='whitepaper'>
                      WHITE PAPER <img src={caret2} id='caret2' />
                      <div className='dropdown3'>
                        <a
                          href='https://fishingtown.io/assets/whitepaper.pdf'
                          target='_blank'
                        >
                          <li>English Version</li>
                        </a>
                        <a
                          href='https://fishingtown.io/assets/whitepaper_por.pdf'
                          target='_blank'
                        >
                          <li>Portuguese Version</li>
                        </a>
                      </div>
                    </li>
                  </a>
                  <a
                    href='https://fishingtown.io/assets/pitchdeck.pdf'
                    target='_blank'
                  >
                    <li>PITCH DECK</li>
                  </a>
                  <a
                    href='https://fishing-town-official.gitbook.io/fishing-town-whitepaper/'
                    target='_blank'
                  >
                    <li>GITBOOK</li>
                  </a>
                </div>
              </li>
              <li id='marketplace'>
                <a href='https://market.fishingtown.io/'>MARKETPLACE</a>
              </li>

              <li className='dropdown1'>
                MORE <img src={caret} id='caret' />
                <div className='dropdown2'>
                  <li onClick={props.scrollToStatistics}>STATISTICS</li>
                  <li onClick={props.scrollToMint}>MINT AND BURN</li>
                  <li onClick={props.scrollToPartner}>PARTNER</li>
                  <li onClick={props.scrollToAudit}>AUDITED</li>
                  <li onClick={props.scrollToTeam}>TEAM</li>
                </div>
              </li>
              {/* <li className='language'> */}
              {/* {language.map((lng) => (
                <li
                  className='language'
                  onClick={() => {
                    props.setLanguage(lng)
                  }}
                >
                  {lng.toUpperCase()}
                </li>
              ))} */}
              <li
                className='language'
                onClick={() => setShowLanguage((showLanguage) => !showLanguage)}
              >
                {props.language.toUpperCase()}
                <img src={caret} id='caret' />
              </li>
              {showLanguage === false ? null : (
                <ul className='language-dropdown'>
                  {language.map((lng) => (
                    <li
                      className='language'
                      onClick={() => {
                        props.setLanguage(lng)
                        setShowLanguage(false)
                      }}
                    >
                      {lng.toUpperCase()}
                    </li>
                  ))}
                </ul>
              )}

              {/* </li> */}
            </ul>
          </div>
        </nav>
        <nav className='mobile-nav'>
          <img src={logo} onClick={props.scrollToHome} />
          <ul>
            <li
              className='language'
              onClick={() => setShowLanguage((showLanguage) => !showLanguage)}
            >
              {props.language.toUpperCase()}
              <img src={caret} id='caret' />
            </li>
            {showLanguage === false ? null : (
              <ul className='language-dropdown'>
                {language.map((lng) => (
                  <li
                    className='language'
                    onClick={() => {
                      props.setLanguage(lng)
                      setShowLanguage(false)
                    }}
                  >
                    {lng.toUpperCase()}
                  </li>
                ))}
              </ul>
            )}
          </ul>
          <img
            src={hamburger}
            id='hamburger'
            onClick={() => setIsOpen((prev) => !prev)}
          />
        </nav>
        {isOpen ? (
          <div className='mobile-nav-menu open'>
            <img src={close} id='close' onClick={() => setIsOpen(false)} />

            <ul>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToFeatures()
                }}
              >
                FEATURES
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToStatistics()
                }}
              >
                STATISTICS
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToNFT()
                }}
              >
                NFTs ITEM
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToTokenomics()
                }}
              >
                TOKENOMIC
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToRoadmap()
                }}
              >
                ROADMAP
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToMint()
                }}
              >
                MINT AND BURN
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToPartner()
                }}
              >
                PARTNER
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToAudit()
                }}
              >
                AUDITED
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToTeam()
                }}
              >
                TEAM
              </li>
              <a
                href='https://fishingtown.io/assets/whitepaper.pdf'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  WHITE PAPER
                </li>
              </a>
              <a
                href='https://fishingtown.io/assets/pitchdeck.pdf'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  PITCH DECK
                </li>
              </a>
              <a
                href='https://fishing-town-official.gitbook.io/fishing-town-whitepaper/'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  GITBOOK
                </li>
              </a>
            </ul>
          </div>
        ) : (
          <div className='mobile-nav-menu'>
            <img src={close} id='close' onClick={() => setIsOpen(false)} />

            <ul>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToFeatures()
                }}
              >
                FEATURES
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToStatistics()
                }}
              >
                STATISTICS
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToNFT()
                }}
              >
                NFTs ITEM
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToTokenomics()
                }}
              >
                TOKENOMIC
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToRoadmap()
                }}
              >
                ROADMAP
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToMint()
                }}
              >
                MINT AND BURN
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToPartner()
                }}
              >
                PARTNER
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToAudit()
                }}
              >
                AUDITED
              </li>
              <li
                onClick={() => {
                  setIsOpen(false)
                  props.scrollToTeam()
                }}
              >
                TEAM
              </li>
              <a
                href='https://fishingtown.io/assets/whitepaper.pdf'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  WHITE PAPER
                </li>
              </a>
              <a
                href='https://fishingtown.io/assets/pitchdeck.pdf'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  PITCH DECK
                </li>
              </a>
              <a
                href='https://fishing-town-official.gitbook.io/fishing-town-whitepaper/'
                target='_blank'
              >
                <li
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  GITBOOK
                </li>
              </a>
            </ul>
          </div>
        )}
      </>
    )
  }
}

export default Navbar
