import React from 'react'
import team1 from '../../assets/team/1.png'
import team2 from '../../assets/team/2.png'
import team3 from '../../assets/team/3.png'
import team4 from '../../assets/team/4.png'
import team5 from '../../assets/team/5.png'
import team6 from '../../assets/team/6.png'
import team7 from '../../assets/team/7.png'
import team8 from '../../assets/team/8.png'
import team9 from '../../assets/team/9.png'
function Team(props){

  
  if(props.language==='cn')
    return (
      <>
        <div className='team cn' ref={props.teamRef}>
          <h1>团队</h1>
          <div className='grid'>
            <div className='box'>
              <img src={team1} />
              <div>
                <h2>CEO/CMO</h2>
                <p>JACK WHALE</p>
              </div>
            </div>
            <div className='box'>
              <img src={team2} />
              <div>
                <h2>CPO</h2>
                <p>LUCAS WHALE</p>
              </div>
            </div>
            <div className='box'>
              <img src={team3} />
              <div>
                <h2>CTO</h2>
                <p>TONY ORCA</p>
              </div>
            </div>
            <div className='box'>
              <img src={team4} />
              <div>
                <h2>游戏开发者</h2>
                <p>JIMMY SHARK</p>
              </div>
            </div>
            <div className='box'>
              <img src={team5} />
              <div>
                <h2>艺术指导</h2>
                <p>MAD SALMON</p>
              </div>
            </div>
            <div className='box'>
              <img src={team6} />
              <div>
                <h2>行销</h2>
                <p>QUAY ENGAWA</p>
              </div>
            </div>
            <div className='box'>
              <img src={team8} />
              <div>
                <h2>行销</h2>
                <p>JELLY FISH</p>
              </div>
            </div>
            <div className='box'>
              <img src={team9} />
              <div>
                <h2>行销</h2>
                <p>CANNED</p>
              </div>
            </div>
          </div>
        </div>
      </>
    )

    if (props.language === 'jp')
      return (
        <>
          <div className='team jp' ref={props.teamRef}>
            <h1>チーム</h1>
            <div className='grid'>
              <div className='box'>
                <img src={team1} />
                <div>
                  <h2>CEO/CMO</h2>
                  <p>JACK WHALE</p>
                </div>
              </div>
              <div className='box'>
                <img src={team2} />
                <div>
                  <h2>CPO</h2>
                  <p>LUCAS WHALE</p>
                </div>
              </div>
              <div className='box'>
                <img src={team3} />
                <div>
                  <h2>CTO</h2>
                  <p>TONY ORCA</p>
                </div>
              </div>
              <div className='box'>
                <img src={team4} />
                <div>
                  <h2>ゲームデッフ</h2>
                  <p>JIMMY SHARK</p>
                </div>
              </div>
              <div className='box'>
                <img src={team5} />
                <div>
                  <h2>アートダイレクター</h2>
                  <p>MAD SALMON</p>
                </div>
              </div>
              <div className='box'>
                <img src={team6} />
                <div>
                  <h2>マーケティング</h2>
                  <p>QUAY ENGAWA</p>
                </div>
              </div>
              <div className='box'>
                <img src={team8} />
                <div>
                  <h2>マーケティング</h2>
                  <p>JELLY FISH</p>
                </div>
              </div>
              <div className='box'>
                <img src={team9} />
                <div>
                  <h2>マーケティング</h2>
                  <p>CANNED</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )

      if (props.language === 'esp')
        return (
          <>
            <div className='team' ref={props.teamRef}>
              <h1>Equipo</h1>
              <div className='grid'>
                <div className='box'>
                  <img src={team1} />
                  <div>
                    <h2>CEO/CMO</h2>
                    <p>JACK WHALE</p>
                  </div>
                </div>
                <div className='box'>
                  <img src={team2} />
                  <div>
                    <h2>CPO</h2>
                    <p>LUCAS WHALE</p>
                  </div>
                </div>
                <div className='box'>
                  <img src={team3} />
                  <div>
                    <h2>CTO</h2>
                    <p>TONY ORCA</p>
                  </div>
                </div>
                <div className='box'>
                  <img src={team4} />
                  <div>
                    <h2>Desarrollador del juego</h2>
                    <p>JIMMY SHARK</p>
                  </div>
                </div>
                <div className='box'>
                  <img src={team5} />
                  <div>
                    <h2>Director de Arte</h2>
                    <p>MAD SALMON</p>
                  </div>
                </div>
                <div className='box'>
                  <img src={team6} />
                  <div>
                    <h2>MARKETING</h2>
                    <p>QUAY ENGAWA</p>
                  </div>
                </div>
                <div className='box'>
                  <img src={team8} />
                  <div>
                    <h2>MARKETING</h2>
                    <p>JELLY FISH</p>
                  </div>
                </div>
                <div className='box'>
                  <img src={team9} />
                  <div>
                    <h2>MARKETING</h2>
                    <p>CANNED</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )

      if (props.language === 'pts')
        return (
          <>
            <div className='team' ref={props.teamRef}>
              <h1>Equipe</h1>
              <div className='grid'>
                <div className='box'>
                  <img src={team1} />
                  <div>
                    <h2>CEO/CMO</h2>
                    <p>JACK WHALE</p>
                  </div>
                </div>
                <div className='box'>
                  <img src={team2} />
                  <div>
                    <h2>CPO</h2>
                    <p>LUCAS WHALE</p>
                  </div>
                </div>
                <div className='box'>
                  <img src={team3} />
                  <div>
                    <h2>CTO</h2>
                    <p>TONY ORCA</p>
                  </div>
                </div>
                <div className='box'>
                  <img src={team4} />
                  <div>
                    <h2>Desenvolvedor do jogo</h2>
                    <p>JIMMY SHARK</p>
                  </div>
                </div>
                <div className='box'>
                  <img src={team5} />
                  <div>
                    <h2>Diretor de arte</h2>
                    <p>MAD SALMON</p>
                  </div>
                </div>
                <div className='box'>
                  <img src={team6} />
                  <div>
                    <h2>MARKETING</h2>
                    <p>QUAY ENGAWA</p>
                  </div>
                </div>
                <div className='box'>
                  <img src={team8} />
                  <div>
                    <h2>MARKETING</h2>
                    <p>JELLY FISH</p>
                  </div>
                </div>
                <div className='box'>
                  <img src={team9} />
                  <div>
                    <h2>MARKETING</h2>
                    <p>CANNED</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      else
        return (
          <>
            <div className='team' ref={props.teamRef}>
              <h1>TEAM</h1>
              <div className='grid'>
                <div className='box'>
                  <img src={team1} />
                  <div>
                    <h2>CEO/CMO</h2>
                    <p>JACK WHALE</p>
                  </div>
                </div>
                <div className='box'>
                  <img src={team2} />
                  <div>
                    <h2>CPO</h2>
                    <p>LUCAS WHALE</p>
                  </div>
                </div>
                <div className='box'>
                  <img src={team3} />
                  <div>
                    <h2>CTO</h2>
                    <p>TONY ORCA</p>
                  </div>
                </div>
                <div className='box'>
                  <img src={team4} />
                  <div>
                    <h2>GAME DEV.</h2>
                    <p>JIMMY SHARK</p>
                  </div>
                </div>
                <div className='box'>
                  <img src={team5} />
                  <div>
                    <h2>ART DIRECTOR</h2>
                    <p>MAD SALMON</p>
                  </div>
                </div>
                <div className='box'>
                  <img src={team6} />
                  <div>
                    <h2>MARKETING</h2>
                    <p>QUAY ENGAWA</p>
                  </div>
                </div>
                <div className='box'>
                  <img src={team8} />
                  <div>
                    <h2>MARKETING</h2>
                    <p>JELLY FISH</p>
                  </div>
                </div>
                <div className='box'>
                  <img src={team9} />
                  <div>
                    <h2>MARKETING</h2>
                    <p>CANNED</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
}
export default Team