import React, { useState ,useEffect} from 'react'
import logo from '../../assets/nav/logo.png'
import hamburger from '../../assets/nav/hamburger.png'
import close from '../../assets/nav/close.png'
import coin from '../../assets/dapp/coin.png'
import {Link} from 'react-router-dom'
import Web3 from 'web3'
function Navbar(props) {
  const [user, setUser] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  // useEffect(async ()=>{
  //    let web3
  //    if (window.ethereum) {
  //      web3 = new Web3(window.ethereum)
  //      await window.ethereum.enable()
  //    } else if (window.web3) {
  //      web3 = new Web3(window.web3.currentProvider)
  //    }
  //    web3.eth.getAccounts().then((account) => {
  //      setUser(account[0])
  //    })
  // },[])

  const showAddress =(a)=>{
    let l = a.length
    return `CA : ${a[0]}${a[1]}${a[2]}${a[3]}${a[4]}${a[5]}${a[6]}...${a[l-5]}${a[l - 4]}${a[l - 3]}${a[l - 2]}${a[l - 1]}`
  }


  return (
    <>
      <nav className='nav2 _04b'>
        <Link to='/'>
          <img src={logo} onClick={props.scrollToHome} id='logo' />
        </Link>
        <div className='nav-menu'>
          <ul>
            <li>
              <Link to='/'>ABOUT</Link>
            </li>

            <li>
              <a href='https://fishingtown.io/assets/pitchdeck.pdf' target='_blank'>
                PITCH DECK
              </a>
            </li>
            <li>
              <a href='https://fishingtown.io/assets/pitchdeck.pdf' target='_blank'>
                WHITE PAPER
              </a>
            </li>
          </ul>
        </div>
        {user != null ? (
          <h2 id='connected'>{showAddress(user)}</h2>
        ) : (
          <div id='connect-btn'>
            <h1
              onClick={async () => {
                var web3
                if (window.ethereum) {
                  web3 = new Web3(window.ethereum)
                  await window.ethereum.enable()
                } else if (window.web3) {
                  web3 = new Web3('https://data-seed-prebsc-1-s1.binance.org:8545')
                }
                web3.eth.getAccounts().then((account) => {
                  setUser(account[0])
                })
              }}
            >
              CONNECT
              <br />
              WALLET
            </h1>
            <img src={coin} />
          </div>
        )}
      </nav>
      <nav className='mobile-nav2'>
        <Link to='/'>
          <img src={logo} />
        </Link>
        <img src={hamburger} id='hamburger' onClick={() => setIsOpen((prev) => !prev)} />
      </nav>
      {isOpen ? (
        <div className='mobile-nav-menu open'>
          <img src={close} id='close' onClick={() => setIsOpen(false)} />

          <ul>
            <li>
              <Link to='/'>ABOUT</Link>
            </li>
            <li>
              <a href='https://fishingtown.io/assets/pitchdeck.pdf' target='_blank'>
                PITCH DECK
              </a>
            </li>
            <li>
              <a href='https://fishingtown.io/assets/whitepaper.pdf' target='_blank'>
                WHITE PAPER
              </a>
            </li>
          </ul>
        </div>
      ) : (
        <div className='mobile-nav-menu'>
          <img src={close} id='close' onClick={() => setIsOpen(false)} />

          <ul>
            <li>ABOUT</li>
            <li>
              <a href='https://fishingtown.io/assets/pitchdeck.pdf' target='_blank'>
                PITCH DECK
              </a>
            </li>
            <li>
              <a href='https://fishingtown.io/assets/pitchdeck.pdf' target='_blank'>
                WHITE PAPER
              </a>
            </li>
          </ul>
        </div>
      )}
    </>
  )
}

export default Navbar
