import React from 'react'
import phase1 from '../../assets/roadmap/phase1.png'
import phase2 from '../../assets/roadmap/phase2.png'
import phase3 from '../../assets/roadmap/phase3.png'
import phase4 from '../../assets/roadmap/phase4.png'
import phase5 from '../../assets/roadmap/phase5.png'
function Roadmap(props) {
  if (props.language === 'cn')
    return (
      <>
        <div className='roadmap cn' ref={props.roadmapRef}>
          <h1>路线图</h1>
          <div className='line'></div>
          <div className='flex'>
            <div className='box'>
              <img src={phase1} />
              <ul>
                <li>技术研究:区块 链、NFT、桥梁</li>
                <li>钓鱼小镇游戏开发的开始</li>
                <li>NFT 游戏的商业计划</li>
              </ul>
            </div>
            <div className='box'>
              <img src={phase2} />
              <ul>
                <li>私人预售、IDO、 公开预售 (BSC)</li>
                <li>游戏实况 1.0</li>
                <li>市场</li>
                <li>社区活动和比赛</li>
              </ul>
            </div>
            <div className='box'>
              <img src={phase3} className='filter' />
              <ul>
                <li>
                  游 戏 发 布 Fishing Town 游戏的新功 能:新地图、新游
                  戏内物品、新 NFT
                </li>
                <li>合并钓鱼竿</li>
                <li>新 的 NPC 和 与 NPC 的关系</li>
                <li>钓竿设备特点</li>
                <li>铸造采矿设备 NFT</li>
                <li>铸造木刻设备 NFT</li>
                <li>排行榜</li>
                <li>股权 $FHTN</li>
                <li>投票系统</li>
                <li>游戏版本 1.1 - 移 动版(Android 和 IOS)</li>
              </ul>
            </div>
            <div className='box'>
              <img src={phase4} className='filter' />
              <ul>
                <li>游戏中心发布</li>
                <li>对战 房屋和房屋升级系统</li>
                <li>新 NPC 和新关系</li>
                <li>烹饪</li>
                <li>在 CEX上市 $FHTN</li>
              </ul>
            </div>
            <div className='box'>
              <img src={phase5} className='filter' />
              <ul>
                <li>对战 </li>
                <li>更多跨链NFT</li>
                <li>水族馆 ,养殖鱼, 喂鱼,鱼缸装饰</li>
                <li>元界 (多人玩家)</li>
              </ul>
            </div>
          </div>
        </div>
      </>
    )

  if (props.language === 'jp')
    return (
      <>
        <div className='roadmap jp' ref={props.roadmapRef}>
          <h1>ロードマップ</h1>
          <div className='line'></div>
          <div className='flex'>
            <div className='box'>
              <img src={phase1} />
              <ul>
                <li>テクノロジーの研究：ブロックチェーン、NFT、ブリッジ。</li>
                <li>フィッシングタウンゲーム開発の始まり。</li>
                <li>NFTゲームの事業計画。</li>
              </ul>
            </div>
            <div className='box'>
              <img src={phase2} />
              <ul>
                <li>
                  プライベートプレセール、IDOパブリックプレセール（BSC）。
                </li>
                <li>ゲームリリース 1.0</li>
                <li>マーケットプレイス </li>
                <li>コミュニティイベント＆試合</li>
              </ul>
            </div>
            <div className='box'>
              <img src={phase3} className='filter' />
              <ul>
                <li>
                  ゲームリリースフィッシングタウンゲームの新機能：新しいマップ、新しいゲーム内アイテム、新しいNFT。
                </li>
                <li>釣り竿をマージします。</li>
                <li>新しいNPCとNPCとの関係作り。</li>
                <li>釣り竿機器の機能。</li>
                <li>ミントマイニング機器NFT。</li>
                <li>ミント木版画機器NFT。</li>
                <li>リーダーボード。</li>
                <li>$ FHTNをステーキング</li>
                <li>投票システム</li>
                <li>ゲームリリース1.1-モバイル（AndroidおよびIOS）</li>
              </ul>
            </div>
            <div className='box'>
              <img src={phase4} className='filter' />
              <ul>
                <li>ゲームセンターのリリース。</li>
                <li>家と家のアップグレードシステム。</li>
                <li>新しいNPCとNPCとの関係作り。</li>
                <li>調理。</li>
                <li>CEXに$ FHTNをリストします。</li>
              </ul>
            </div>
            <div className='box'>
              <img src={phase5} className='filter' />
              <ul>
                <li>PVP.</li>
                <li>より多くのクロスチェーンNFT。</li>
                <li>水族館-繁殖魚-給餌皿-水槽の装飾。</li>
                <li>メタバース（マルチプレイヤー）。</li>
              </ul>
            </div>
          </div>
        </div>
      </>
    )

  if (props.language === 'esp')
    return (
      <>
        <div className='roadmap esp' ref={props.roadmapRef}>
          <h1>Mapa de ruta</h1>
          <div className='line'></div>
          <div className='flex'>
            <div className='box'>
              <img src={phase1} />
              <ul>
                <li>Investigación tecnológica: Blockchain, NFTS, Bridge. </li>
                <li>El comienzo del desarrollo del juego Fishing Town. </li>
                <li>Plan de negocios para el juego NFT.</li>
              </ul>
            </div>
            <div className='box'>
              <img src={phase2} />
              <ul>
                <li>Preventa Privada, Preventa Pública IDO (BSC).</li>
                <li>LANZAMIENTO DEL JUEGO 1.0</li>
                <li>Mercado.</li>
                <li>Concursos y eventos comunitarios</li>
              </ul>
            </div>
            <div className='box'>
              <img src={phase3} className='filter' />
              <ul>
                <li>
                  Lanzamiento del juego Nuevas funciones en el juego Fishing
                  Town: Nuevo mapa, Nuevos elementos del juego, Nuevos NFT.
                </li>
                <li>Caña de pescar fusionada.</li>
                <li>Nuevo NPC y creación de relaciones con NPC.</li>
                <li>Fishing rod equipment features.</li>
                <li>Equipo de minería minteado NFTS.</li>
                <li>Equipos de corte de madera minteado NFT.</li>
                <li>Tabla de líderes.</li>
                <li>Stacking SFHTN</li>
                <li>Sistema de votación</li>
                <li>Lanzamiento del juego 1.1 - Móvil (Android e IOS)</li>
              </ul>
            </div>
            <div className='box'>
              <img src={phase4} className='filter' />
              <ul>
                <li>Lanzamiento del Game Center.</li>
                <li>Sistema de casas y sistema de mejora de casas.</li>
                <li>Nuevos NPC y creación de relaciones con NPC.</li>
                <li>Cocinando</li>
                <li>Incluir SFHTN en CEX.</li>
              </ul>
            </div>
            <div className='box'>
              <img src={phase5} className='filter' />
              <ul>
                <li>PVP.</li>
                <li>Más NFT de cadena cruzada.</li>
                <li>
                  Acuario: cría de peces. - alimentar a los peces. -Decoraciones
                  para peceras.
                </li>
                <li>Metaverse (multi-player).</li>
              </ul>
            </div>
          </div>
        </div>
      </>
    )
    
 
   if (props.language === 'pts')
     return (
       <>
         <div className='roadmap pts' ref={props.roadmapRef}>
           <h1>ROADMAP</h1>
           <div className='line'></div>
           <div className='flex'>
             <div className='box'>
               <img src={phase1} />
               <ul>
                 <li>Pesquisa de tecnologias: Blockchain, NFTs, Bridge</li>
                 <li>Começo de desenvolvimento do jogo Fishing Town</li>
                 <li>Planos de negócio para o jogo NFTs</li>
               </ul>
             </div>
             <div className='box'>
               <img src={phase2} />
               <ul>
                 <li>Pré venda privada, Ido publica, pré venda (BSC)</li>
                 <li>GAME RELEASE 1.0</li>
                 <li>Mercado</li>
                 <li>Eventos para a comunidade e competições</li>
               </ul>
             </div>
             <div className='box'>
               <img src={phase3} className='filter' />
               <ul>
                 <li>
                   Lançamento no jogos de novas funções no jogo Fishing town:
                   novo mapa, novos itens no jogo, novas NFTs
                 </li>
                 <li>Fundir varas de pesca</li>
                 <li>Novos NPC e relações com NPC</li>
                 <li>Vara de pesca com novas características</li>
                 <li>Equipamento de mineração de mint NFT</li>
                 <li>Equipamento de corte de madeira mintado NFT</li>
                 <li>Ranking </li>
                 <li>Staking de $FHTN</li>
                 <li>Sistema de voto</li>
                 <li>Lançamento do jogo 1.1 - Versão mobile (Android e IOS)</li>
               </ul>
             </div>
             <div className='box'>
               <img src={phase4} className='filter' />
               <ul>
                 <li>Lançamento do Game center</li>
                 <li>Sistema de casas e upgrades</li>
                 <li>Novos npcs e criação de relacionamento com NPC</li>
                 <li>Cozinhar</li>
                 <li>Listagem do $FHTN na CEX</li>
               </ul>
             </div>
             <div className='box'>
               <img src={phase5} className='filter' />
               <ul>
                 <li>PVP.</li>
                 <li>Mais cadeia cruzada de NFTs</li>
                 <li>
                   Aquário - Breeding de peixes - alimentar peixes - decorações
                   para o tanque de peixes
                 </li>
                 <li>Metaverse (multi-player).</li>
               </ul>
             </div>
           </div>
         </div>
       </>
     )
   else
     return (
       <>
         <div className='roadmap' ref={props.roadmapRef}>
           <h1>ROADMAP</h1>
           <div className='line'></div>
           <div className='flex'>
             <div className='box'>
               <img src={phase1} />
               <ul>
                 <li>Technology reseaching: Blockchain, NFTs, Bridge.</li>
                 <li>The beginning of Fishing Town game development.</li>
                 <li>Business plan for NFTs game.</li>
               </ul>
             </div>
             <div className='box'>
               <img src={phase2} />
               <ul>
                 <li>Private pre-sale, IDO public pre-sale (BSC).</li>
                 <li>GAME RELEASE 1.0</li>
                 <li>Marketplace. </li>
                 <li>Community Event & Competitions</li>
               </ul>
             </div>
             <div className='box'>
               <img src={phase3} className='filter' />
               <ul>
                 <li>
                   Game release New features on Fishing Town game: New map, New
                   In-game Items, New NFTs.
                 </li>
                 <li>Merging fishing rod.</li>
                 <li>New NPC and relationship making with NPC.</li>
                 <li>Fishing rod equipment features.</li>
                 <li>Mint mining equipment NFTs.</li>
                 <li>Mint woodcutting equipment NFTs.</li>
                 <li>Leader Board.</li>
                 <li>STAKING $FHTN</li>
                 <li>Voting system</li>
                 <li>Game release 1.1 - Mobile (Android and IOS)</li>
               </ul>
             </div>
             <div className='box'>
               <img src={phase4} className='filter' />
               <ul>
                 <li>Game center release.</li>
                 <li>House and house upgrade system.</li>
                 <li>New NPC and relationship making with NPC.</li>
                 <li>Cooking.</li>
                 <li>Listing $FHTN on CEX.</li>
               </ul>
             </div>
             <div className='box'>
               <img src={phase5} className='filter' />
               <ul>
                 <li>PVP.</li>
                 <li>More cross-chain NFTs.</li>
                 <li>
                   Aquarium - breeding fish. - feeding fish. - fish tank
                   decorations.
                 </li>
                 <li>Metaverse (multi-player).</li>
               </ul>
             </div>
           </div>
         </div>
       </>
     )
}

export default Roadmap
