import React, { useEffect, useState } from 'react'
import logo from '../../assets/nav/logo.png'
import btn from '../../assets/home/btn.png'
import fish1 from '../../assets/home/fish1.png'
import fish2 from '../../assets/home/fish2.png'
import fish3 from '../../assets/home/fish3.png'
import fish4 from '../../assets/home/fish4.png'
import ig from '../../assets/home/ig.png'
import twitter from '../../assets/home/twitter.png'
import discord from '../../assets/home/discord.png'
import facebook from '../../assets/home/facebook.png'
import telegram from '../../assets/home/telegram.png'
import medium from '../../assets/home/medium.png'
import { Link } from 'react-router-dom'

function Home(props) {
  const [X, SetX] = useState(0)
  const [Y, SetY] = useState(0)

  const OnMouseMove = (event) => {
    SetX(event.clientX)
    SetY(event.clientY)
  }

  useEffect(() => {
    window.addEventListener('mousemove', OnMouseMove)
    return () => {
      window.removeEventListener('mousemove', OnMouseMove)
    }
  }, [X, Y])
  
  if(props.language==='cn')
  {
    return (
      <>
        <div className='home cn' ref={props.homeRef}>
          <section className='home-contact'>
            <a
              href='https://www.facebook.com/Fishing-Town-BSC-113402467792132'
              target='_blank'
            >
              <img src={facebook} />
            </a>
            <a href='https://twitter.com/FishingTownbsc' target='_blank'>
              <img src={twitter} />
            </a>
            <a
              href='https://www.instagram.com/fishingtown.io/'
              target='_blank '
            >
              <img src={ig} />
            </a>
            <a href='https://t.me/fishingtownofficial' target='_blank'>
              <img src={telegram} />
            </a>
            <a href='https://discord.gg/3UaxjEkyv2' target='_blank'>
              <img src={discord} />
            </a>
            <a href='https://fishingtown-io.medium.com/' target='_blank'>
              <img src={medium} />
            </a>
          </section>
          <img src={logo} className='logo' />
          <img
            src={fish1}
            id='fish1'
            className='fish'
            style={{
              transform: `translateX(${-X / 40}px) translateY(${-Y / 20}px)`,
            }}
          />
          <img
            src={fish2}
            id='fish2'
            className='fish'
            style={{
              transform: `translateX(${-X / 25}px) translateY(${-Y / 50}px)`,
            }}
          />
          <img
            src={fish3}
            id='fish3'
            className='fish'
            style={{
              transform: `translateX(${-X / 80}px) translateY(${-Y / 25}px)`,
            }}
          />
          <img
            src={fish4}
            id='fish4'
            className='fish'
            style={{
              transform: `translateX(${-X / 36}px) translateY(${-Y / 25}px)`,
            }}
          />
          <div className='flex'>
            <a className='play-btn-cn' href='https://play.fishingtown.io'>
              打开游戏
            </a>
            <div className='flex2'>
              <a
                className='play-btn2-cn fhtn'
                href='https://pancakeswap.finance/swap?outputCurrency=0x87be0b856960f57fb0104ef0922cd1dacbd37f64'
                target='_blank'
              >
                購買 $FHTN
              </a>
              <a
                className='play-btn2-cn gil'
                href='https://pancakeswap.finance/swap?outputCurrency=0xc5B9B7dCB988c86bA37853761fEA692772C9937d'
                target='_blank'
              >
                購買 $Gil
              </a>
            </div>
            <div className='flex2'>
              <a
                className='play-btn2-cn fhtn'
                href='https://market.fishingtown.io/'
              >
                市場
              </a>
              <a
                className='play-btn2 fhtn'
                // href='https://market.fishingtown.io/'
              >
                <Link to='/fishingtown-pad'>FISHINGTOWN-PAD</Link>
              </a>
            </div>
            <div className='flex2'>
              <a className='play-btn2 ' href='https://staking.fishingtown.io/'>
                STAKING
              </a>
              <a
                className='play-btn2 '
                href='https://profile-presale.fishingtown.io/'
              >
                BUY NFT PROFILE
              </a>
            </div>
            <div className='flex2'>
              <a className='play-btn2 ' href='https://axe-presale.fishingtown.io/' target = '_blank'>
                BUY AXE
              </a>
              <a
                className='play-btn2 '
                href='https://pickaxe-presale.fishingtown.io/' target = '_blank'
              >
                BUY PICKAXE
              </a>
            </div>
          </div>
        </div>
      </>
    )
  }

  if (props.language === 'jp') {
    return (
      <>
        <div className='home cn' ref={props.homeRef}>
          <section className='home-contact'>
            <a
              href='https://www.facebook.com/Fishing-Town-BSC-113402467792132'
              target='_blank'
            >
              <img src={facebook} />
            </a>
            <a href='https://twitter.com/FishingTownbsc' target='_blank'>
              <img src={twitter} />
            </a>
            <a
              href='https://www.instagram.com/fishingtown.io/'
              target='_blank '
            >
              <img src={ig} />
            </a>
            <a href='https://t.me/fishingtownofficial' target='_blank'>
              <img src={telegram} />
            </a>
            <a href='https://discord.gg/3UaxjEkyv2' target='_blank'>
              <img src={discord} />
            </a>
            <a href='https://fishingtown-io.medium.com/' target='_blank'>
              <img src={medium} />
            </a>
          </section>
          <img src={logo} className='logo' />
          <img
            src={fish1}
            id='fish1'
            className='fish'
            style={{
              transform: `translateX(${-X / 40}px) translateY(${-Y / 20}px)`,
            }}
          />
          <img
            src={fish2}
            id='fish2'
            className='fish'
            style={{
              transform: `translateX(${-X / 25}px) translateY(${-Y / 50}px)`,
            }}
          />
          <img
            src={fish3}
            id='fish3'
            className='fish'
            style={{
              transform: `translateX(${-X / 80}px) translateY(${-Y / 25}px)`,
            }}
          />
          <img
            src={fish4}
            id='fish4'
            className='fish'
            style={{
              transform: `translateX(${-X / 36}px) translateY(${-Y / 25}px)`,
            }}
          />
          <div className='flex'>
            <a className='play-btn-cn' href='https://play.fishingtown.io'>
              ゲームを始める
            </a>
            <div className='flex2'>
              <a
                className='play-btn2-cn fhtn'
                href='https://pancakeswap.finance/swap?outputCurrency=0x87be0b856960f57fb0104ef0922cd1dacbd37f64'
                target='_blank'
              >
                $FHTN を買う
              </a>
              <a
                className='play-btn2-cn gil'
                href='https://pancakeswap.finance/swap?outputCurrency=0xc5B9B7dCB988c86bA37853761fEA692772C9937d'
                target='_blank'
              >
                $Gil を買う
              </a>
            </div>
            <div className='flex2'>
              <a
                className='play-btn2-cn fhtn'
                href='https://market.fishingtown.io/'
              >
                マーケットプレイス
              </a>
              <a
                className='play-btn2 fhtn'
                // href='https://market.fishingtown.io/'
              >
                <Link to='/fishingtown-pad'>FISHINGTOWN-PAD</Link>
              </a>
            </div>
            <div className='flex2'>
              <a className='play-btn2 ' href='https://staking.fishingtown.io/'>
                STAKING
              </a>
              <a
                className='play-btn2 '
                href='https://profile-presale.fishingtown.io/'
              >
                BUY NFT PROFILE
              </a>
            </div>
            <div className='flex2'>
              <a className='play-btn2 ' href='https://axe-presale.fishingtown.io/' target = '_blank'>
                BUY AXE
              </a>
              <a
                className='play-btn2 '
                href='https://pickaxe-presale.fishingtown.io/' target = '_blank'
              >
                BUY PICKAXE
              </a>
            </div>
          </div>
        </div>
      </>
    )
  }

  if(props.language==='esp') {
    return (
      <>
        <div className='home' ref={props.homeRef}>
          <section className='home-contact'>
            <a
              href='https://www.facebook.com/Fishing-Town-BSC-113402467792132'
              target='_blank'
            >
              <img src={facebook} />
            </a>
            <a href='https://twitter.com/FishingTownbsc' target='_blank'>
              <img src={twitter} />
            </a>
            <a
              href='https://www.instagram.com/fishingtown.io/'
              target='_blank '
            >
              <img src={ig} />
            </a>
            <a href='https://t.me/fishingtownofficial' target='_blank'>
              <img src={telegram} />
            </a>
            <a href='https://discord.gg/3UaxjEkyv2' target='_blank'>
              <img src={discord} />
            </a>
            <a href='https://fishingtown-io.medium.com/' target='_blank'>
              <img src={medium} />
            </a>
          </section>
          <img src={logo} className='logo' />
          <img
            src={fish1}
            id='fish1'
            className='fish'
            style={{
              transform: `translateX(${-X / 40}px) translateY(${-Y / 20}px)`,
            }}
          />
          <img
            src={fish2}
            id='fish2'
            className='fish'
            style={{
              transform: `translateX(${-X / 25}px) translateY(${-Y / 50}px)`,
            }}
          />
          <img
            src={fish3}
            id='fish3'
            className='fish'
            style={{
              transform: `translateX(${-X / 80}px) translateY(${-Y / 25}px)`,
            }}
          />
          <img
            src={fish4}
            id='fish4'
            className='fish'
            style={{
              transform: `translateX(${-X / 36}px) translateY(${-Y / 25}px)`,
            }}
          />
          <div className='flex'>
            <a className='play-btn' href='https://play.fishingtown.io'>
              Jugar juego!!!
            </a>
            <div className='flex2'>
              <a
                className='play-btn2 fhtn'
                href='https://pancakeswap.finance/swap?outputCurrency=0x87be0b856960f57fb0104ef0922cd1dacbd37f64'
                target='_blank'
              >
                Comprar $FHTN
              </a>
              <a
                className='play-btn2 gil'
                href='https://pancakeswap.finance/swap?outputCurrency=0xc5B9B7dCB988c86bA37853761fEA692772C9937d'
                target='_blank'
              >
                Comprar $GIL
              </a>
            </div>
            <div className='flex2'>
              <a
                className='play-btn2 fhtn'
                href='https://market.fishingtown.io/'
              >
                Mercado
              </a>
              <a
                className='play-btn2 fhtn'
                // href='https://market.fishingtown.io/'
              >
                <Link to='/fishingtown-pad'>FISHINGTOWN-PAD</Link>
              </a>
            </div>

            <div className='flex2'>
              <a className='play-btn2 ' href='https://staking.fishingtown.io/'>
                STAKING
              </a>
              <a
                className='play-btn2 '
                href='https://profile-presale.fishingtown.io/'
              >
                BUY NFT PROFILE
              </a>
            </div>
            <div className='flex2'>
              <a className='play-btn2 ' href='https://axe-presale.fishingtown.io/' target = '_blank'>
                BUY AXE
              </a>
              <a
                className='play-btn2 '
                href='https://pickaxe-presale.fishingtown.io/' target = '_blank'
              >
                BUY PICKAXE
              </a>
            </div>
          </div>
        </div>
      </>
    )
  }
  if(props.language==='pts') {
    return (
      <>
        <div className='home' ref={props.homeRef}>
          <section className='home-contact'>
            <a
              href='https://www.facebook.com/Fishing-Town-BSC-113402467792132'
              target='_blank'
            >
              <img src={facebook} />
            </a>
            <a href='https://twitter.com/FishingTownbsc' target='_blank'>
              <img src={twitter} />
            </a>
            <a
              href='https://www.instagram.com/fishingtown.io/'
              target='_blank '
            >
              <img src={ig} />
            </a>
            <a href='https://t.me/fishingtownofficial' target='_blank'>
              <img src={telegram} />
            </a>
            <a href='https://discord.gg/3UaxjEkyv2' target='_blank'>
              <img src={discord} />
            </a>
            <a href='https://fishingtown-io.medium.com/' target='_blank'>
              <img src={medium} />
            </a>
          </section>
          <img src={logo} className='logo' />
          <img
            src={fish1}
            id='fish1'
            className='fish'
            style={{
              transform: `translateX(${-X / 40}px) translateY(${-Y / 20}px)`,
            }}
          />
          <img
            src={fish2}
            id='fish2'
            className='fish'
            style={{
              transform: `translateX(${-X / 25}px) translateY(${-Y / 50}px)`,
            }}
          />
          <img
            src={fish3}
            id='fish3'
            className='fish'
            style={{
              transform: `translateX(${-X / 80}px) translateY(${-Y / 25}px)`,
            }}
          />
          <img
            src={fish4}
            id='fish4'
            className='fish'
            style={{
              transform: `translateX(${-X / 36}px) translateY(${-Y / 25}px)`,
            }}
          />
          <div className='flex'>
            <a className='play-btn' href='https://play.fishingtown.io'>
              Jogar jogo!!!
            </a>
            <div className='flex2'>
              <a
                className='play-btn2 fhtn'
                href='https://pancakeswap.finance/swap?outputCurrency=0x87be0b856960f57fb0104ef0922cd1dacbd37f64'
                target='_blank'
              >
                Comprar $FHTN
              </a>
              <a
                className='play-btn2 gil'
                href='https://pancakeswap.finance/swap?outputCurrency=0xc5B9B7dCB988c86bA37853761fEA692772C9937d'
                target='_blank'
              >
                Comprar $GIL
              </a>
            </div>
            <div className='flex2'>
              <a
                className='play-btn2 fhtn'
                href='https://market.fishingtown.io/'
              >
                MARKETPLACE
              </a>
              <a
                className='play-btn2 fhtn'
                // href='https://market.fishingtown.io/'
              >
                <Link to='/fishingtown-pad'>FISHINGTOWN-PAD</Link>
              </a>
            </div>

            <div className='flex2'>
              <a className='play-btn2 ' href='https://staking.fishingtown.io/'>
                Mercado
              </a>
              <a
                className='play-btn2 '
                href='https://profile-presale.fishingtown.io/'
              >
                BUY NFT PROFILE
              </a>
            </div>
            <div className='flex2'>
              <a className='play-btn2 ' href='https://axe-presale.fishingtown.io/' target = '_blank'>
                BUY AXE
              </a>
              <a
                className='play-btn2 '
                href='https://pickaxe-presale.fishingtown.io/' target = '_blank'
              >
                BUY PICKAXE
              </a>
            </div>
          </div>
        </div>
      </>
    )
  }
  else {
    return (
      <>
        <div className='home' ref={props.homeRef}>
          <section className='home-contact'>
            <a
              href='https://www.facebook.com/Fishing-Town-BSC-113402467792132'
              target='_blank'
            >
              <img src={facebook} />
            </a>
            <a href='https://twitter.com/FishingTownbsc' target='_blank'>
              <img src={twitter} />
            </a>
            <a
              href='https://www.instagram.com/fishingtown.io/'
              target='_blank '
            >
              <img src={ig} />
            </a>
            <a href='https://t.me/fishingtownofficial' target='_blank'>
              <img src={telegram} />
            </a>
            <a href='https://discord.gg/3UaxjEkyv2' target='_blank'>
              <img src={discord} />
            </a>
            <a href='https://fishingtown-io.medium.com/' target='_blank'>
              <img src={medium} />
            </a>
          </section>
          <img src={logo} className='logo' />
          <img
            src={fish1}
            id='fish1'
            className='fish'
            style={{
              transform: `translateX(${-X / 40}px) translateY(${-Y / 20}px)`,
            }}
          />
          <img
            src={fish2}
            id='fish2'
            className='fish'
            style={{
              transform: `translateX(${-X / 25}px) translateY(${-Y / 50}px)`,
            }}
          />
          <img
            src={fish3}
            id='fish3'
            className='fish'
            style={{
              transform: `translateX(${-X / 80}px) translateY(${-Y / 25}px)`,
            }}
          />
          <img
            src={fish4}
            id='fish4'
            className='fish'
            style={{
              transform: `translateX(${-X / 36}px) translateY(${-Y / 25}px)`,
            }}
          />
          <div className='flex'>
            <a className='play-btn' href='https://play.fishingtown.io'>
              PLAY GAME!!!
            </a>
            <div className='flex2'>
              <a
                className='play-btn2 fhtn'
                href='https://pancakeswap.finance/swap?outputCurrency=0x87be0b856960f57fb0104ef0922cd1dacbd37f64'
                target='_blank'
              >
                BUY $FHTN
              </a>
              <a
                className='play-btn2 gil'
                href='https://pancakeswap.finance/swap?outputCurrency=0xc5B9B7dCB988c86bA37853761fEA692772C9937d'
                target='_blank'
              >
                BUY $GIL
              </a>
            </div>
            <div className='flex2'>
              <a
                className='play-btn2 fhtn'
                href='https://market.fishingtown.io/'
              >
                MARKETPLACE
              </a>
              <a
                className='play-btn2 fhtn'
                // href='https://market.fishingtown.io/'
              >
                <Link to='/fishingtown-pad'>FISHINGTOWN-PAD</Link>
              </a>
            </div>

            <div className='flex2'>
              <a className='play-btn2 ' href='https://staking.fishingtown.io/'>
                STAKING
              </a>
              <a
                className='play-btn2 '
                href='https://profile-presale.fishingtown.io/'
              >
                BUY NFT PROFILE
              </a>
            </div>
            <div className='flex2'>
              <a className='play-btn2 ' href='https://axe-presale.fishingtown.io/' target = '_blank'>
                BUY AXE
              </a>
              <a
                className='play-btn2 '
                href='https://pickaxe-presale.fishingtown.io/' target = '_blank'
              >
                BUY PICKAXE
              </a>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Home
