import React, { useEffect, useRef,useState } from 'react'
import ReactAudioPlayer from 'react-audio-player'
import Home from './Components/Home/Home'
import Navbar from './Components/Home/Navbar'
import Features from './Components/Home/Features'
import Statistics from './Components/Home/Statistics'
import NFT from './Components/Home/NFT'
import Roadmap from './Components/Home/Roadmap'
import Mint from './Components/Home/Mint'
import Team from './Components/Home/Team'
import Footer from './Components/Home/Footer'
import Tokenomic from './Components/Home/Tokenomic'
import Partner from './Components/Home/Partner'
import Audit from './Components/Home/Audit'
import song from './assets/song.wav'
import 'aos/dist/aos.css'

function App() {
  const [language,setLanguage] = useState('eng')
  const [bubble,setBubble] = useState([]);
  const homeRef = useRef(null)
  const featuresRef = useRef(null)
  const statisticsRef = useRef(null)
  const nftRef = useRef(null)
  const tokenomicsRef = useRef(null)
  const roadmapRef = useRef(null)
  const teamRef = useRef(null)
  const mintRef = useRef(null)
  const partnerRef = useRef(null)
  const auditRef = useRef(null)

  useEffect(() => {
     setLanguage(localStorage.getItem('language'))
  }, [])

  useEffect(() => {
    localStorage.setItem('language', language)

  },[language])

  const scrollToHome = () => {
    homeRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  const scrollToFeatures = () => {
    featuresRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  const scrollToStatistics = () => {
    statisticsRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  const scrollToNFT = () => {
    nftRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  const scrollToTokenomics = () => {
    tokenomicsRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  const scrollToRoadmap = () => {
    roadmapRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  const scrollToTeam = () => {
    teamRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  const scrollToMint = ()=>{
    mintRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  const scrollToPartner = ()=>{
    partnerRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  const scrollToAudit = ()=>{
    auditRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div className='app'>
      <ReactAudioPlayer src={song} controls={false} autoPlay={true} volume={0.4} />
      <Navbar
        scrollToHome={scrollToHome}
        scrollToFeatures={scrollToFeatures}
        scrollToStatistics={scrollToStatistics}
        scrollToNFT={scrollToNFT}
        scrollToTokenomics={scrollToTokenomics}
        scrollToRoadmap={scrollToRoadmap}
        scrollToTeam={scrollToTeam}
        scrollToMint={scrollToMint}
        scrollToPartner={scrollToPartner}
        scrollToAudit={scrollToAudit}
        setLanguage={setLanguage}
        language={language}
      />
      <div className='bg'>
        <Home homeRef={homeRef} language={language} />
        <Features featuresRef={featuresRef} language={language} />
        <div className='pagebreaker'></div>
        <Statistics statisticsRef={statisticsRef} language={language} />
        <div className='pagebreaker'></div>
        <NFT nftRef={nftRef} language={language} />
        <div className='pagebreaker'></div>
        <Tokenomic tokenomicsRef={tokenomicsRef} language={language} />
        <Roadmap roadmapRef={roadmapRef} language={language} />
        <Mint mintRef={mintRef} language={language} />
        <Partner partnerRef={partnerRef} language={language} />
        <Audit auditRef={auditRef} language={language} />
        <Team teamRef={teamRef} language={language} />
      </div>
      <Footer language={language} />
      {/* {decoration.map((a)=>a)} */}
    </div>
  )
}

export default App
