import React from 'react'
import banner from '../../assets/banner.gif'
import airdrop from '../../assets/dapp/airdrop.png'
import {Link} from 'react-router-dom'
import Countdown, { zeroPad } from 'react-countdown'
export default function Home() {

  const Completionist = () => (
    <div className='countdown'>
      <h5>Available Now!!!</h5>
    </div>
  )

  return (
    <>
      <div className='dapp'>
        <div className='flex'>
          <div className='canvas'>
            <div className='img'></div>
          </div>
          <div className='flex2'>
            <Link to='/fishingtown-pad/privatepresale'>
              <div className='box'>
                <div id='privatesale' />
                <div>
                  <h1>WHITELIST PRESALE</h1>
                  <div className='flex3'>
                    <h2>
                      Total: <span>7,000,000 FHTN</span>
                    </h2>
                    <h2>
                      Price: <span>0.03 BUSD</span>
                    </h2>
                    <h2>
                      Min: <span>100 BUSD</span>
                    </h2>
                    <h2>
                      Max: <span>300 BUSD</span>
                    </h2>
                    <h2>
                      Vesting:{' '}
                      <span>
                        Unlock 15% at listing on PCS, remaining distributed
                        every month for 6 months
                      </span>
                    </h2>
                    <h2>
                      Access: <span>Private</span>
                    </h2>
                  </div>
                </div>
              </div>
            </Link>
            <a href='https://staking.fishingtown.io/' target='_blank'>
              <div className='box'>
                <div id='seedfund' />
                <div>
                  <h1 className='staking'>STAKING</h1>
                  <div className='flex3'></div>
                </div>
              </div>
            </a>
            {/* <Link to='/fishingtown-pad/privateClaim'>
              <div className='box '>
                <div id='seedfund' />
                <div>
                  <h1>Private Claim</h1>
                  <div className='flex3'>
                    <h2>
                      Total: <span>62,400 FHTN</span>
                    </h2>
                    <h2>
                      Price: <span>0.05 BUSD</span>
                    </h2>
                    <h2>
                      Access: <span>Private</span>
                    </h2>
                  </div>
                </div>
              </div>
            </Link> */}
            {/* <Link to='/fishingtown-pad/airdrop'>
            <div className='box disable '>
              <div id='airdrop' />
              <div>
                <h1>FHTN Airdrop</h1>
                <div className='flex3'>
                  <h2>
                    Total: <span>62,400 FHTN</span>
                  </h2>
                  <h2>
                    Price: <span>0.05 BUSD</span>
                  </h2>
                  <h2>
                    Access: <span>Private</span>
                  </h2>
                </div>
              </div>
            </div>
            </Link> */}
            {/* <Link to='/fishingtown-pad/seedfund'>
            <div className='box disable'>
              <div id='seedfund' />
              <div>
                <h1>SEEDFUND</h1>
                <div className='flex3'>
                  <h2>
                    Vesting: <span>Unlock 10% at listing on PCS, remaining distributed every month for 10 months</span>
                  </h2>
                </div>
              </div>
            </div>
            </Link> */}
          </div>
        </div>
      </div>
    </>
  )
}
