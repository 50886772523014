import React from 'react'
import rod1 from '../../assets/nft/1.gif'
import rod2 from '../../assets/nft/2.gif'
import rod3 from '../../assets/nft/3.gif'
import rod4 from '../../assets/nft/4.gif'
import rod5 from '../../assets/nft/5.gif'
import rod6 from '../../assets/nft/6.gif'
import rod7 from '../../assets/nft/7.gif'
import glitter from '../../assets/nft/glitter.gif'
import sao from '../../assets/nft/sao.png'
import showroom from '../../assets/nft/showroom.gif'
function NFT(props) {
  if (props.language === 'cn')
    return (
      <>
        <div className='NFT' ref={props.nftRef}>
          <h1>NFTs 物品</h1>
          {/* <img src={showroom}/> */}
          <div className='mobile'>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod1} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod2} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod3} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod4} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod5} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod6} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod7} className='rod' />
              <img src={sao} className='sao' />
            </div>
          </div>
        </div>
      </>
    )

  if (props.language === 'jp')
    return (
      <>
        <div className='NFT jp' ref={props.nftRef}>
          <h1>NFTs アイテム</h1>
          {/* <img src={showroom}/> */}
          <div className='mobile'>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod1} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod2} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod3} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod4} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod5} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod6} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod7} className='rod' />
              <img src={sao} className='sao' />
            </div>
          </div>
        </div>
      </>
    )
  if (props.language === 'esp')
    return (
      <>
        <div className='NFT' ref={props.nftRef}>
          <h1>Objetos NFTs</h1>
          {/* <img src={showroom}/> */}
          <div className='mobile'>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod1} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod2} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod3} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod4} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod5} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod6} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod7} className='rod' />
              <img src={sao} className='sao' />
            </div>
          </div>
        </div>
      </>
    )
  if (props.language === 'pts')
    return (
      <>
        <div className='NFT' ref={props.nftRef}>
          <h1>Items NFTs</h1>
          {/* <img src={showroom}/> */}
          <div className='mobile'>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod1} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod2} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod3} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod4} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod5} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod6} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod7} className='rod' />
              <img src={sao} className='sao' />
            </div>
          </div>
        </div>
      </>
    )
  else
    return (
      <>
        <div className='NFT' ref={props.nftRef}>
          <h1>NFTs ITEM</h1>
          {/* <img src={showroom}/> */}
          <div className='mobile'>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod1} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod2} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod3} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod4} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod5} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod6} className='rod' />
              <img src={sao} className='sao' />
            </div>
            <div className='box'>
              <img src={glitter} className='glitter' />
              <img src={glitter} className='glitter2' />
              <img src={glitter} className='glitter3' />
              <img src={rod7} className='rod' />
              <img src={sao} className='sao' />
            </div>
          </div>
        </div>
      </>
    )
}

export default NFT
