import React from 'react'
import soon from '../../assets/partner/soon.png'
import certik from '../../assets/audit/certik.png'
import inspex from '../../assets/audit/inspex.png'
import pekshield from '../../assets/audit/pekshield.jpg'
export default function audit(props){
  

  if (props.language === 'cn')
    return (
      <>
        <div className='audit cn' ref={props.auditRef}>
          <h1>审计</h1>
          <div className='grid'>
            <img src={certik} id='soon' />
            <img src={inspex} id='soon' />
            <img src={pekshield} id='soon' />
            <img src={soon} id='soon' />
          </div>
        </div>
      </>
    )

    if (props.language === 'jp')
      return (
        <>
          <div className='audit cn' ref={props.auditRef}>
            <h1>監査済み</h1>
            <div className='grid'>
              <img src={certik} id='soon' />
              <img src={inspex} id='soon' />
              <img src={pekshield} id='soon' />
              <img src={soon} id='soon' />
            </div>
          </div>
        </>
      )

    if (props.language === 'esp')
    return (
      <>
        <div className='audit' ref={props.auditRef}>
          <h1>Auditorías</h1>
          <div className='grid'>
            <img src={certik} id='soon' />
            <img src={inspex} id='soon' />
            <img src={pekshield} id='soon' />
            <img src={soon} id='soon' />
          </div>
        </div>
      </>
    )

    if (props.language === 'pts')
      return (
        <>
          <div className='audit' ref={props.auditRef}>
            <h1>Auditorias</h1>
            <div className='grid'>
              <img src={certik} id='soon' />
              <img src={inspex} id='soon' />
              <img src={pekshield} id='soon' />
              <img src={soon} id='soon' />
            </div>
          </div>
        </>
      )
    else
      return (
        <>
          <div className='audit' ref={props.auditRef}>
            <h1>AUDITED</h1>
            <div className='grid'>
              <img src={certik} id='soon' />
              <img src={inspex} id='soon' />
              <img src={pekshield} id='soon' />
              <img src={soon} id='soon' />
            </div>
          </div>
        </>
      )
} 