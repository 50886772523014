import React from 'react'
import Home from './Components/Dapp/Home'
import Navbar from './Components/Dapp/Navbar'
import Footer from './Components/Dapp/Footer'

export default function Dapp(){

    return (
      <>
        <div className='app'>
          <Navbar />
          <Home/>
          <Footer/>
        </div>
      </>
    )
}