import React, { useState, useEffect } from 'react'
import coin from '../../assets/dapp/coin.png'
import back from '../../assets/dapp/back.png'
import Web3 from 'web3'
import { Link } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
export default function PrivateClaim() {
  const [isClaim01,setIsClaim01] = useState(true)
  const [isClaim02, setIsClaim02] = useState(true)
  const [isClaim03, setIsClaim03] = useState(true)
  const [isClaim04, setIsClaim04] = useState(true)
  const [isClaim05, setIsClaim05] = useState(true)
  const [isClaim06, setIsClaim06] = useState(true)
  const [isClaim07, setIsClaim07] = useState(true)
  const [amount, setAmount] = useState(null)
  const [allow, setAllow] = useState(null)
  const [count, setCount] = useState(0)
  const ca = '0x97FcD761a3467BD4EFcba75f9b50c07Cabae6685'
  const [user, setUser] = useState(null)
  const web3 = new Web3(window.ethereum)
  const abi = [
    {
      inputs: [
        { internalType: 'address', name: '_tokenAddress', type: 'address' },
      ],
      stateMutability: 'nonpayable',
      type: 'constructor',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: '_address',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: '_timestamp',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: '_amount',
          type: 'uint256',
        },
      ],
      name: 'ClaimToken',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'Paused',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'Unpaused',
      type: 'event',
    },
    {
      inputs: [{ internalType: 'address', name: '_user', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'claim02',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'claim03',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'claim04',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'claim05',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'claim06',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'claim07',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '_whitelist', type: 'address' },
        { internalType: 'uint256', name: '_amount', type: 'uint256' },
        { internalType: 'uint256', name: '_startTime', type: 'uint256' },
        { internalType: 'uint256', name: '_endTime', type: 'uint256' },
      ],
      name: 'deposit',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'emergencyWithdraw',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: '_addr', type: 'address' }],
      name: 'getIsClaim02',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: '_addr', type: 'address' }],
      name: 'getIsClaim03',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: '_addr', type: 'address' }],
      name: 'getIsClaim04',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: '_addr', type: 'address' }],
      name: 'getIsClaim05',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: '_addr', type: 'address' }],
      name: 'getIsClaim06',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: '_addr', type: 'address' }],
      name: 'getIsClaim07',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address[]', name: '_addrs', type: 'address[]' },
        { internalType: 'uint256', name: '_amount', type: 'uint256' },
        { internalType: 'uint256', name: '_startTime', type: 'uint256' },
      ],
      name: 'multipleDeposit',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'pause',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'paused',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'tokenAddress',
      outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'totalBalance',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'unpause',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: '_user', type: 'address' }],
      name: 'userInfoOf',
      outputs: [
        {
          components: [
            {
              internalType: 'uint256',
              name: 'withdrawalAmount',
              type: 'uint256',
            },
            { internalType: 'uint256', name: 'depositAmount', type: 'uint256' },
            { internalType: 'uint256', name: 'startTime', type: 'uint256' },
            { internalType: 'uint256', name: 'endTime', type: 'uint256' },
          ],
          internalType: 'struct FishingTownVesting.UserInfo',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '_user', type: 'address' },
        { internalType: 'uint256', name: '_timestamp', type: 'uint256' },
      ],
      name: 'withdrawableTokenByTime',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '_user', type: 'address' },
        { internalType: 'uint256', name: '_timestamp', type: 'uint256' },
      ],
      name: 'withdrawableTokenOf',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
  ]
  const getContract = () => {
    let contract = new web3.eth.Contract(abi, ca)
    return contract
  }


  useEffect(async () => {
    let web3
    if (window.ethereum) {
      web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
    } else if (window.web3) {
      web3 = new Web3('https://data-seed-prebsc-1-s1.binance.org:8545')
    }
    web3.eth.getAccounts().then((account) => {
      setUser(account[0])
    })
  }, [user])

  useEffect(async () => {
    if (user != null) {
      let contract = getContract()
    //   contract.methods.isClaim.call({from:user}).then(res=>console.log(res))
        let mycontract = new web3.eth.Contract(abi,ca)
        mycontract.methods.getIsClaim02(user).call({from:user}).then(res=>{
            setIsClaim01(res)
            
        }
        )
        mycontract.methods
          .getIsClaim03(user)
          .call({ from: user })
          .then((res) => setIsClaim02(res))
        mycontract.methods
          .getIsClaim04(user)
          .call({ from: user })
          .then((res) => setIsClaim03(res))
        mycontract.methods
          .getIsClaim05(user)
          .call({ from: user })
          .then((res) => setIsClaim04(res))
        mycontract.methods
          .getIsClaim06(user)
          .call({ from: user })
          .then((res) => setIsClaim05(res))
        mycontract.methods
          .getIsClaim07(user)
          .call({ from: user })
          .then((res) => setIsClaim06(res))
        // await web3.eth.getStorageAt(ca,10,(err,res)=>{
        //     console.log(res)
        // })
    }
  }, [user,count])

  return (
    <>
      <div className='dapp'>
        <Link to='/fishingtown-pad'>
          <img src={back} id='back' />
        </Link>
        <div className='flex'>
          <div className='flex2'>
            <div className='box2'>
              <div>
                <h1>WHITELIST PRESALE</h1>
                <div className='flex3'>
                  <h2>
                    Total: <span>7,000,000 FHTN</span>
                  </h2>
                  <h2>
                    Price: <span>0.03 BUSD</span>
                  </h2>
                  <h2>
                    Min: <span>100 BUSD</span>
                  </h2>
                  <h2>
                    Max: <span>300 BUSD</span>
                  </h2>
                  <h2>
                    Vesting:{' '}
                    <span>
                      Unlock 15% at listing on PCS, remaining distributed every
                      month for 6 months
                    </span>
                  </h2>
                  <h2>
                    Access: <span>Private</span>
                  </h2>
                </div>
                <div>
                  <h3>PROJECT DETAIL</h3>
                  <p>
                    A blockchain game is inspired by fishing life and a passion
                    in aquatic animals with old-school art pixel design.
                    "Play-Fun-Earn", the main theme of the game, means that the
                    game will maintain the balance of playing and earning which
                    will be the part that every player is looking forward to.
                  </p>
                </div>
              </div>
            </div>
            {/* {isMobile ? (
              <div className='box3'>
                <h1>Please buy on Computer</h1>
              </div>
            ) : (
              <div className='box3'>
                <div>
                  <div className='flex4'>
                    <img src={coin} />
                    <h2>
                      TOTAL <span>$FHTN</span>
                    </h2>
                    <img src={coin} />
                  </div>
                  <div className='flex4'>
                    <input type='text' readOnly value={amount} />
                  </div>
                </div>
                <div className='flex4'>
                  <button className='disable'>CLAIM</button>
                </div>
              </div>
            )} */}
          </div>
          <div className='box2'>
            <div className='flex5'>
              <div className='claim-box2 header'>
                <h1>Date</h1>
                <h2>%</h2>
              </div>
              {/* <div className='claim-box'>
                <h1>23 Dec, 1.10pm UTC</h1>
                <h2>15%</h2>

                <button
                  onClick={() => {
                    let contract = getContract()
                    contract.methods.claim02().send({ from: user })
                  }}
                >
                  CLAIM
                </button>
              </div> */}
              <div className='claim-box'>
                <h1>23 Jan, 1.10pm UTC</h1>
                <h2>14.17%</h2>

                {!isClaim01 ? (
                  <button
                    className=''
                    onClick={() => {
                      let contract = getContract()
                      contract.methods.claim02().send({ from: user })
                      setCount((count) => count + 1)
                    }}
                  >
                    CLAIM
                  </button>
                ) : (
                  <button className='disable'>CLAIM</button>
                )}
              </div>
              <div className='claim-box'>
                <h1>23 Feb, 1.10pm UTC</h1>
                <h2>14.17%</h2>
                {!isClaim02 ? (
                  <button
                    className=''
                    onClick={() => {
                      let contract = getContract()
                      contract.methods.claim03().send({ from: user })
                      setCount((count) => count + 1)
                    }}
                  >
                    CLAIM
                  </button>
                ) : (
                  <button className='disable'>CLAIM</button>
                )}
              </div>
              <div className='claim-box'>
                <h1>23 Mar, 1.10pm UTC</h1>
                <h2>14.17%</h2>
                {!isClaim03 ? (
                  <button
                    className=''
                    onClick={() => {
                      let contract = getContract()
                      contract.methods.claim04().send({ from: user })
                      setCount((count) => count + 1)
                    }}
                  >
                    CLAIM
                  </button>
                ) : (
                  <button className='disable'>CLAIM</button>
                )}
              </div>
              <div className='claim-box'>
                <h1>23 Apr, 1.10pm UTC</h1>
                <h2>14.17%</h2>
                {!isClaim04 ? (
                  <button
                    className=''
                    onClick={() => {
                      let contract = getContract()
                      contract.methods.claim05().send({ from: user })
                      setCount((count) => count + 1)
                    }}
                  >
                    CLAIM
                  </button>
                ) : (
                  <button className='disable'>CLAIM</button>
                )}
              </div>
              <div className='claim-box'>
                <h1>23 May, 1.10pm UTC</h1>
                <h2>14.17%</h2>
                {!isClaim05 ? (
                  <button
                    className=''
                    onClick={() => {
                      let contract = getContract()
                      contract.methods.claim06().send({ from: user })
                      setCount((count) => count + 1)
                    }}
                  >
                    CLAIM
                  </button>
                ) : (
                  <button className='disable'>CLAIM</button>
                )}
              </div>

              <div className='claim-box'>
                <h1>23 Jun, 1.10pm UTC</h1>
                <h2>14.17%</h2>
                {!isClaim06 ? (
                  <button
                    className=''
                    onClick={() => {
                      let contract = getContract()
                      contract.methods.claim07().send({ from: user })
                      setCount((count) => count + 1)
                    }}
                  >
                    CLAIM
                  </button>
                ) : (
                  <button className='disable'>CLAIM</button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
