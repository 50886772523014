import React, { useState, useEffect } from 'react'
// import firebase from '../firebase/firebase.js'
import EV from 'email-validator'
import facebook from '../../assets/home/facebook.png'
import twitter from '../../assets/home/twitter.png'
import ig from '../../assets/home/ig.png'
import telegram from '../../assets/home/telegram.png'
import discord from '../../assets/home/discord.png'
import medium from '../../assets/home/medium.png'
import fish1 from '../../assets/footer/fish1.png'
import fish2 from '../../assets/footer/fish2.png'
import fish3 from '../../assets/footer/fish3.png'
import footer from '../../assets/footervid.mp4'
import axios from 'axios'
import 'video-react/dist/video-react.css'
import { Player, ControlBar, Shortcut } from 'video-react'
function Footer(props) {
  const [X, SetX] = useState(0)
  const [Y, SetY] = useState(0)
  const [email, setEmail] = useState(null)
  const [isEmail, setIsEmail] = useState(false)

  const OnMouseMove = (event) => {
    SetX(event.clientX)
    SetY(event.clientY)
  }

  const onSubscribe = (e) => {
    e.preventDefault()
    if (isEmail && email != '') {
      axios
        .post('https://whispering-shelf-18892.herokuapp.com/newemail', {
          email: email,
        })
        .then(alert('Subscibe successful'))
    }
  }
  const onInputChange = (e) => {
    setEmail(e.target.value)
  }

  useEffect(() => {
    window.addEventListener('mousemove', OnMouseMove)
    return () => {
      window.removeEventListener('mousemove', OnMouseMove)
    }
  }, [X, Y])

  useEffect(() => {
    setIsEmail(EV.validate(email))
  }, [email])

  
  if (props.language === 'cn')
    return (
      <>
        <div className='footer'>
          <div className='container'>
            <Player
              playsInline
              autoPlay={true}
              muted={true}
              src={footer}
              loop={true}
              controls={false}
              fluid={false}
              className='bg'
            >
              <ControlBar disabled />
              <Shortcut clickable={false} />
            </Player>
          </div>
          <div className='subscribe'>
            {/* <form>
              <input type='email' className='input' onChange={onInputChange} />
              {isEmail ? (
                <button type='submit' className='isemail' onClick={onSubscribe}>
                  订阅
                </button>
              ) : (
                <button type='submit' className='notemail' disabled>
                  订阅
                </button>
              )}
            </form> */}
          </div>
          <div className='contact'>
            <a
              href='https://www.facebook.com/Fishing-Town-BSC-113402467792132'
              target='_blank'
            >
              <img src={facebook} />
            </a>
            <a href='https://twitter.com/FishingTownbsc' target='_blank'>
              <img src={twitter} />
            </a>
            <a
              href='https://www.instagram.com/fishingtown.io/'
              target='_blank '
            >
              <img src={ig} />
            </a>
            <a href='https://t.me/fishingtownofficial' target='_blank'>
              <img src={telegram} />
            </a>
            <a href='https://discord.gg/3UaxjEkyv2' target='_blank'>
              <img src={discord} />
            </a>
            <a href='https://fishingtown-io.medium.com/' target='_blank'>
              <img src={medium} />
            </a>
          </div>
        </div>
      </>
    )

  if (props.language === 'jp')
    return (
      <>
        <div className='footer'>
          <div className='container'>
            <Player
              playsInline
              autoPlay={true}
              muted={true}
              src={footer}
              loop={true}
              controls={false}
              fluid={false}
              className='bg'
            >
              <ControlBar disabled />
              <Shortcut clickable={false} />
            </Player>
          </div>
          <div className='subscribe'>
            {/* <form>
              <input type='email' className='input' onChange={onInputChange} />
              {isEmail ? (
                <button type='submit' className='isemail' onClick={onSubscribe}>
                  登録
                </button>
              ) : (
                <button type='submit' className='notemail' disabled>
                  登録
                </button>
              )}
            </form> */}
          </div>
          <div className='contact'>
            <a
              href='https://www.facebook.com/Fishing-Town-BSC-113402467792132'
              target='_blank'
            >
              <img src={facebook} />
            </a>
            <a href='https://twitter.com/FishingTownbsc' target='_blank'>
              <img src={twitter} />
            </a>
            <a
              href='https://www.instagram.com/fishingtown.io/'
              target='_blank '
            >
              <img src={ig} />
            </a>
            <a href='https://t.me/fishingtownofficial' target='_blank'>
              <img src={telegram} />
            </a>
            <a href='https://discord.gg/3UaxjEkyv2' target='_blank'>
              <img src={discord} />
            </a>
            <a href='https://fishingtown-io.medium.com/' target='_blank'>
              <img src={medium} />
            </a>
          </div>
        </div>
      </>
    )

    else
      return (
        <>
          <div className='footer'>
            <div className='container'>
              <Player
                playsInline
                autoPlay={true}
                muted={true}
                src={footer}
                loop={true}
                controls={false}
                fluid={false}
                className='bg'
              >
                <ControlBar disabled />
                <Shortcut clickable={false} />
              </Player>
            </div>
            <div className='subscribe'>
              {/* <form>
                <input
                  type='email'
                  className='input'
                  onChange={onInputChange}
                />
                {isEmail ? (
                  <button
                    type='submit'
                    className='isemail'
                    onClick={onSubscribe}
                  >
                    SUBSCRIBE
                  </button>
                ) : (
                  <button type='submit' className='notemail' disabled>
                    SUBSCRIBE
                  </button>
                )}
              </form> */}
            </div>
            <div className='contact'>
              <a
                href='https://www.facebook.com/Fishing-Town-BSC-113402467792132'
                target='_blank'
              >
                <img src={facebook} />
              </a>
              <a href='https://twitter.com/FishingTownbsc' target='_blank'>
                <img src={twitter} />
              </a>
              <a
                href='https://www.instagram.com/fishingtown.io/'
                target='_blank '
              >
                <img src={ig} />
              </a>
              <a href='https://t.me/fishingtownofficial' target='_blank'>
                <img src={telegram} />
              </a>
              <a href='https://discord.gg/3UaxjEkyv2' target='_blank'>
                <img src={discord} />
              </a>
              <a href='https://fishingtown-io.medium.com/' target='_blank'>
                <img src={medium} />
              </a>
            </div>
          </div>
        </>
      )
}

export default Footer
