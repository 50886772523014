import React from 'react'
import textbox2 from '../../assets/statistics/textbox2.png'
import coin from '../../assets/statistics/coin.gif'
function Statistics(props) {
  if (props.language === 'cn')
    return (
      <>
        <div className='statistics cn' ref={props.statisticsRef}>
          <div className='grid'>
            <h1>统计</h1>
            <div className='box'>
              <img src={textbox2} />
              <h2>玩家</h2>
            </div>
            <div className='box'>
              <img src={textbox2} />
              <h2>鱼竿</h2>
            </div>
            <div className='box'>
              <img src={textbox2} />
              <h2>魚</h2>
            </div>
            <div className='box'>
              <img src={textbox2} />
              <h2>市场</h2>
            </div>
            <div className='box'>
              <img src={coin} id='coin' />
            </div>
          </div>
        </div>
      </>
    )

  if (props.language === 'jp')
    return (
      <>
        <div className='statistics jp' ref={props.statisticsRef}>
          <div className='grid'>
            <h1>統計</h1>
            <div className='box'>
              <img src={textbox2} />
              <h2>プレイヤー</h2>
            </div>
            <div className='box'>
              <img src={textbox2} />
              <h2>釣り竿</h2>
            </div>
            <div className='box'>
              <img src={textbox2} />
              <h2>魚</h2>
            </div>
            <div className='box'>
              <img src={textbox2} />
              <h2>マーケットプレース</h2>
            </div>
            <div className='box'>
              <img src={coin} id='coin' />
            </div>
          </div>
        </div>
      </>
    )

  if (props.language === 'esp')
    return (
      <>
        <div className='statistics' ref={props.statisticsRef}>
          <div className='grid'>
            <h1>Estadísticas</h1>
            <div className='box'>
              <img src={textbox2} />
              <h2>Jugadores</h2>
            </div>
            <div className='box'>
              <img src={textbox2} />
              <h2>Cañas de pescar</h2>
            </div>
            <div className='box'>
              <img src={textbox2} />
              <h2>Peces</h2>
            </div>
            <div className='box'>
              <img src={textbox2} />
              <h2>Mercado</h2>
            </div>
            <div className='box'>
              <img src={coin} id='coin' />
            </div>
          </div>
        </div>
      </>
    )
  if (props.language === 'pts')
    return (
      <>
        <div className='statistics' ref={props.statisticsRef}>
          <div className='grid'>
            <h1>Estatisticas</h1>
            <div className='box'>
              <img src={textbox2} />
              <h2>Jugadores</h2>
            </div>
            <div className='box'>
              <img src={textbox2} />
              <h2>Cañas de pescar</h2>
            </div>
            <div className='box'>
              <img src={textbox2} />
              <h2>Peces</h2>
            </div>
            <div className='box'>
              <img src={textbox2} />
              <h2>Mercado</h2>
            </div>
            <div className='box'>
              <img src={coin} id='coin' />
            </div>
          </div>
        </div>
      </>
    )
  else
    return (
      <>
        <div className='statistics' ref={props.statisticsRef}>
          <div className='grid'>
            <h1>Statistics</h1>
            <div className='box'>
              <img src={textbox2} />
              <h2>PLAYERS</h2>
            </div>
            <div className='box'>
              <img src={textbox2} />
              <h2>FISHING ROD</h2>
            </div>
            <div className='box'>
              <img src={textbox2} />
              <h2>FISH</h2>
            </div>
            <div className='box'>
              <img src={textbox2} />
              <h2>MARKETPLACE</h2>
            </div>
            <div className='box'>
              <img src={coin} id='coin' />
            </div>
          </div>
        </div>
      </>
    )
}

export default Statistics
