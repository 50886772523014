import React from 'react'
import fishingrod from '../../assets/mintandburn/(Mint)fishing rod.png'
import fish from '../../assets/mintandburn/(mint)fish.png'
import equipment from '../../assets/mintandburn/(Mint)equipment NFTs.png'
import mining from '../../assets/mintandburn/(Mint)winning equipment.png'
import decoration from '../../assets/mintandburn/(Mint)Aquarium dec NFTs.png'
import event from '../../assets/mintandburn/(Burn)Event.png'
import activity from '../../assets/mintandburn/(Burn)Activity.png'
import reparing from '../../assets/mintandburn/(Burn)Repairing.png'
import refining from '../../assets/mintandburn/(Burn)Refining.png'
import bridging from '../../assets/mintandburn/(Burn)Bridging relationship.png'
import lotto from '../../assets/mintandburn/(Burn)Game lotto.png'
import habitatdec from '../../assets/mintandburn/(Burn)Habitat Dec.png'
import habitatdecsys from '../../assets/mintandburn/(Burn)Habitat Dec System.png'
import fishfeeding from '../../assets/mintandburn/(Burn)Fish-feeding.png'
import aquarium from '../../assets/mintandburn/(Mint)aquarium.png'
import blacksmith from '../../assets/mintandburn/(Burn) Blacksmith.png'
import food from '../../assets/mintandburn/(Burn)food.png'
import aquariumdecsys from '../../assets/mintandburn/(Burn)aquarium Dec.png'
import fishingbreed from '../../assets/mintandburn/(Burn)Fish Breeding.png'
import axe from '../../assets/mintandburn/ax-21.png'
function Mint(props) {
  if (props.language === 'cn')
    return (
      <>
        <div className='mintandburn ' ref={props.mintRef}>
          <h1>铸造和燃烧</h1>
          <div className='flex'>
            <div className='mint'>
              <h2>铸造</h2>
              <div className='phase'>
                <h3>阶段二</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={fishingrod} />
                    <p>铸造钓鱼竿NFTs</p>
                  </div>
                  <div className='box'>
                    <img src={fish} />
                    <p>铸造鱼NFTs</p>
                  </div>
                </div>
              </div>
              <div className='phase'>
                <h3>阶段三</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={equipment} />
                    <p>铸造装备NFTs</p>
                  </div>
                  <div className='box'>
                    <img src={mining} />
                    <p>铸造采矿设备 NFT</p>
                  </div>
                  <div className='box'>
                    <img src={axe} />
                    <p>铸造木刻设备 NFT</p>
                  </div>
                </div>
              </div>
              <div className='phase'>
                <h3>阶段五</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={aquarium} />
                    <p>铸造水族馆 NFT</p>
                  </div>
                  <div className='box'>
                    <img src={decoration} />
                    <p>铸造水族馆 装饰 NFT</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='line'></div>
            <div className='burn'>
              <h2>燃烧</h2>
              <div className='flex3'>
                <div className='box2'>
                  <img src={event} />
                  <h3>事件</h3>
                  <p>消费硬币购买参与票</p>
                </div>
                <div className='box2'>
                  <img src={activity} />
                  <h3>活动</h3>
                  <p>提炼或捕捞比赛</p>
                </div>
              </div>
              <div className='phase'>
                <h3>阶段二</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={reparing} />
                    <p>修理+工艺</p>
                  </div>
                  <div className='box'>
                    <img src={refining} />
                    <p>精制</p>
                  </div>
                  <div className='box'>
                    <img src={blacksmith} />
                    <p>铁匠</p>
                  </div>
                  <div className='box'>
                    <img src={food} />
                    <p>食物</p>
                  </div>
                </div>
              </div>
              <div className='phase'>
                <h3>阶段三</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={lotto} />
                    <p>游戏中心:乐透、赛鱼、轮盘财富</p>
                  </div>
                </div>
              </div>
              <div className='phase'>
                <h3>阶段四</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={habitatdec} />
                    <p>栖息地装饰</p>
                  </div>
                  <div className='box'>
                    <img src={bridging} />
                    <p>鱼和NPC角色之间的桥梁关系</p>
                  </div>
                </div>
              </div>
              <div className='phase'>
                <h3>阶段五</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={habitatdecsys} />
                    <p>人居装饰系统</p>
                  </div>
                  <div className='box'>
                    <img src={aquariumdecsys} />
                    <p>水族箱装饰系统</p>
                  </div>
                  <div className='box'>
                    <img src={fishfeeding} />
                    <p>喂鱼系统</p>
                  </div>
                  <div className='box'>
                    <img src={fishingbreed} />
                    <p>鱼类养殖系统</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )

  if (props.language === 'jp')
    return (
      <>
        <div className='mintandburn ' ref={props.mintRef}>
          <h1>ミント&焼くこと</h1>
          <div className='flex'>
            <div className='mint'>
              <h2>ミント </h2>
              <div className='phase'>
                <h3>フェーズ2</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={fishingrod} />
                    <p>ミニ釣り竿NFT</p>
                  </div>
                  <div className='box'>
                    <img src={fish} />
                    <p>ミントフィッシュNFT</p>
                  </div>
                </div>
              </div>
              <div className='phase'>
                <h3>フェーズ3</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={equipment} />
                    <p>ミント機器NFT</p>
                  </div>
                  <div className='box'>
                    <img src={mining} />
                    <p>ミントマイニング機器NFT</p>
                  </div>
                  <div className='box'>
                    <img src={axe} />
                    <p>ミント木版画機器NFT</p>
                  </div>
                </div>
              </div>
              <div className='phase'>
                <h3>フェーズ5</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={aquarium} />
                    <p>ミント水族館NFT</p>
                  </div>
                  <div className='box'>
                    <img src={decoration} />
                    <p>ミント水族館の装飾NFT</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='line'></div>
            <div className='burn'>
              <h2>焼くこと</h2>
              <div className='flex3'>
                <div className='box2'>
                  <img src={event} />
                  <h3>イベント</h3>
                  <p>参加チケットを購入するためにコインを使う</p>
                </div>
                <div className='box2'>
                  <img src={activity} />
                  <h3>魚を取る為の試合</h3>
                  <p>アクティビティ 釣りの試合を洗練またはキャッチ</p>
                </div>
              </div>
              <div className='phase'>
                <h3>フェーズ2</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={reparing} />
                    <p>修理+クラフト</p>
                  </div>
                  <div className='box'>
                    <img src={refining} />
                    <p>精製</p>
                  </div>
                  <div className='box'>
                    <img src={blacksmith} />
                    <p>
                      （オプション）アプグレードが失敗したら機器に影響がありません。
                    </p>
                  </div>
                  <div className='box'>
                    <img src={food} />
                    <p>食べ物</p>
                  </div>
                </div>
              </div>
              <div className='phase'>
                <h3>フェーズ3</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={lotto} />
                    <p>
                      ゲームセンター：ロット、フィッシュレーシング　ホイールターニング
                    </p>
                  </div>
                </div>
              </div>
              <div className='phase'>
                <h3>フェーズ4</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={habitatdec} />
                    <p>生息地を飾る</p>
                  </div>
                  <div className='box'>
                    <img src={bridging} />
                    <p>魚とNPSキャラクターの架け橋関係</p>
                  </div>
                </div>
              </div>
              <div className='phase'>
                <h3>フェーズ5</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={habitatdecsys} />
                    <p>住む所の装飾システム</p>
                  </div>
                  <div className='box'>
                    <img src={aquariumdecsys} />
                    <p>水族館装飾システム</p>
                  </div>
                  <div className='box'>
                    <img src={fishfeeding} />
                    <p>魚の餌付けシステム</p>
                  </div>
                  <div className='box'>
                    <img src={fishingbreed} />
                    <p>魚の繁殖システム</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )

  if (props.language === 'esp')
    return (
      <>
        <div className='mintandburn' ref={props.mintRef}>
          <h1>Minteo y Quema</h1>
          <div className='flex'>
            <div className='mint'>
              <h2>Minteo y Quema</h2>
              <div className='phase'>
                <h3>Fase 2</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={fishingrod} />
                    <p>Minteo Cañas de pescar NFTs</p>
                  </div>
                  <div className='box'>
                    <img src={fish} />
                    <p>Minteo de peces NFTs</p>
                  </div>
                </div>
              </div>
              <div className='phase'>
                <h3>Fase 3</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={equipment} />
                    <p>Minteo de equipamiento NFTs</p>
                  </div>
                  <div className='box'>
                    <img src={mining} />
                    <p>Minteo de equipamiento de minería NFTs</p>
                  </div>
                  <div className='box'>
                    <img src={axe} />
                    <p>Minteo de equipamiento de tala de madera NFTs</p>
                  </div>
                </div>
              </div>
              <div className='phase'>
                <h3>Fase 5</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={aquarium} />
                    <p>Minteo de acuarios NFTs</p>
                  </div>
                  <div className='box'>
                    <img src={decoration} />
                    <p>Minteo de decoración de acuarios NFTs</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='line'></div>
            <div className='burn'>
              <h2>Quema</h2>
              <div className='flex3'>
                <div className='box2'>
                  <img src={event} />
                  <h3>Evento</h3>
                  <p>Gasta monedas para adquirir tickets de participación</p>
                </div>
                <div className='box2'>
                  <img src={activity} />
                  <h3>Actividades</h3>
                  <p>Competencia de refinados o de pesca </p>
                </div>
              </div>
              <div className='phase'>
                <h3>Fase 2</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={reparing} />
                    <p>Reparar + Craftear</p>
                  </div>
                  <div className='box'>
                    <img src={refining} />
                    <p>Refinar</p>
                  </div>
                  <div className='box'>
                    <img src={blacksmith} />
                    <p>Bendición del Herrero</p>
                  </div>
                  <div className='box'>
                    <img src={food} />
                    <p>Comida</p>
                  </div>
                </div>
              </div>
              <div className='phase'>
                <h3>Fase 3</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={lotto} />
                    <p>Game center: Loteria,Carrera de peces,Ruleta</p>
                  </div>
                </div>
              </div>
              <div className='phase'>
                <h3>Fase 4</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={habitatdec} />
                    <p>Decoración de habitat</p>
                  </div>
                  <div className='box'>
                    <img src={bridging} />
                    <p>Crea relaciones entre pescadores y personajes NPC</p>
                  </div>
                </div>
              </div>
              <div className='phase'>
                <h3>Fase 5</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={habitatdecsys} />
                    <p>Sistema de decoración de habitat</p>
                  </div>
                  <div className='box'>
                    <img src={aquariumdecsys} />
                    <p>Sistema de decoración de acuario</p>
                  </div>
                  <div className='box'>
                    <img src={fishfeeding} />
                    <p>Sistema de crianza de peces</p>
                  </div>
                  <div className='box'>
                    <img src={fishingbreed} />
                    <p>Sistema de reproducción de peces</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  if (props.language === 'pts')
    return (
      <>
        <div className='mintandburn' ref={props.mintRef}>
          <h1>Mintagem e queima</h1>
          <div className='flex'>
            <div className='mint'>
              <h2>Mintagem</h2>
              <div className='phase'>
                <h3>Fase 2</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={fishingrod} />
                    <p>Mintagem de varas NTFs</p>
                  </div>
                  <div className='box'>
                    <img src={fish} />
                    <p>Mintagem de peixes NFTs</p>
                  </div>
                </div>
              </div>
              <div className='phase'>
                <h3>Fase 3</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={equipment} />
                    <p>Mintagem de equipamentos NFTs</p>
                  </div>
                  <div className='box'>
                    <img src={mining} />
                    <p>Mintagem de equipamentos de mineração NFTs</p>
                  </div>
                  <div className='box'>
                    <img src={axe} />
                    <p>Mintagem de Machado para cortar madeira NFTs</p>
                  </div>
                </div>
              </div>
              <div className='phase'>
                <h3>Fase 5</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={aquarium} />
                    <p>Mintagem de quario NFTs</p>
                  </div>
                  <div className='box'>
                    <img src={decoration} />
                    <p>Mintagem de decoração de Aquário NFTs</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='line'></div>
            <div className='burn'>
              <h2>Queima</h2>
              <div className='flex3'>
                <div className='box2'>
                  <img src={event} />
                  <h3>Evento</h3>
                  <p>Gastar moedas comprando tickets de participação</p>
                </div>
                <div className='box2'>
                  <img src={activity} />
                  <h3>Atividades</h3>
                  <p>competição de Refino ou pesca de peixes</p>
                </div>
              </div>
              <div className='phase'>
                <h3>Fase 2</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={reparing} />
                    <p>Reparar + Craftar</p>
                  </div>
                  <div className='box'>
                    <img src={refining} />
                    <p>Refino</p>
                  </div>
                  <div className='box'>
                    <img src={blacksmith} />
                    <p>Benção do Ferreiro</p>
                  </div>
                  <div className='box'>
                    <img src={food} />
                    <p>Comida</p>
                  </div>
                </div>
              </div>
              <div className='phase'>
                <h3>Fase 3 </h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={lotto} />
                    <p>Game center: Loteria, corrida de peixes, Roleta</p>
                  </div>
                </div>
              </div>
              <div className='phase'>
                <h3>Fase 4</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={habitatdec} />
                    <p>Decoração de Habitat</p>
                  </div>
                  <div className='box'>
                    <img src={bridging} />
                    <p>Criar relação com pescadores e personagens NPC</p>
                  </div>
                </div>
              </div>
              <div className='phase'>
                <h3>Fase 5</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={habitatdecsys} />
                    <p>Sistema de decoração de habitat</p>
                  </div>
                  <div className='box'>
                    <img src={aquariumdecsys} />
                    <p>Sistema de decoração de aquario</p>
                  </div>
                  <div className='box'>
                    <img src={fishfeeding} />
                    <p>Sistema de criação de peixes</p>
                  </div>
                  <div className='box'>
                    <img src={fishingbreed} />
                    <p>Sistema de reprodução de peixes</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  else
    return (
      <>
        <div className='mintandburn' ref={props.mintRef}>
          <h1>MINT and BURN</h1>
          <div className='flex'>
            <div className='mint'>
              <h2>MINT</h2>
              <div className='phase'>
                <h3>PHASE 2</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={fishingrod} />
                    <p>Mint fishing rod NFTs</p>
                  </div>
                  <div className='box'>
                    <img src={fish} />
                    <p>Mint fish NFTs</p>
                  </div>
                </div>
              </div>
              <div className='phase'>
                <h3>PHASE 3</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={equipment} />
                    <p>Mint equipment NFTs</p>
                  </div>
                  <div className='box'>
                    <img src={mining} />
                    <p>Mint mining equipment NFTs</p>
                  </div>
                  <div className='box'>
                    <img src={axe} />
                    <p>Mint woodcutting equipment NFTs</p>
                  </div>
                </div>
              </div>
              <div className='phase'>
                <h3>PHASE 5</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={aquarium} />
                    <p>Mint aquarium NFTs</p>
                  </div>
                  <div className='box'>
                    <img src={decoration} />
                    <p>Mint aquarium decoration NFTs</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='line'></div>
            <div className='burn'>
              <h2>BURN</h2>
              <div className='flex3'>
                <div className='box2'>
                  <img src={event} />
                  <h3>EVENT</h3>
                  <p>spending coins to purchase participation ticket</p>
                </div>
                <div className='box2'>
                  <img src={activity} />
                  <h3>ACTIVITY</h3>
                  <p>refining or catch fishing competition</p>
                </div>
              </div>
              <div className='phase'>
                <h3>PHASE 2</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={reparing} />
                    <p>Reparing + Craft</p>
                  </div>
                  <div className='box'>
                    <img src={refining} />
                    <p>Refining</p>
                  </div>
                  <div className='box'>
                    <img src={blacksmith} />
                    <p>Blacksmith blessing</p>
                  </div>
                  <div className='box'>
                    <img src={food} />
                    <p>Food</p>
                  </div>
                </div>
              </div>
              <div className='phase'>
                <h3>PHASE 3</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={lotto} />
                    <p>Game center: Lotto, Fish racing, Wheel turning</p>
                  </div>
                </div>
              </div>
              <div className='phase'>
                <h3>PHASE 4</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={habitatdec} />
                    <p>Habitat decorating</p>
                  </div>
                  <div className='box'>
                    <img src={bridging} />
                    <p>
                      Bridging relationship between fishes and NPC characters
                    </p>
                  </div>
                </div>
              </div>
              <div className='phase'>
                <h3>PHASE 5</h3>
                <div className='flex2'>
                  <div className='box'>
                    <img src={habitatdecsys} />
                    <p>Habitat decorating system</p>
                  </div>
                  <div className='box'>
                    <img src={aquariumdecsys} />
                    <p>Aquarium decorating system</p>
                  </div>
                  <div className='box'>
                    <img src={fishfeeding} />
                    <p>Fish-feeding system</p>
                  </div>
                  <div className='box'>
                    <img src={fishingbreed} />
                    <p>Fish-breeding system</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
}

export default Mint
