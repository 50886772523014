import React, { useState, useEffect } from 'react'
import ship from '../../assets/ship.gif'
import island from '../../assets/island.png'
import stone1 from '../../assets/story/stone1.png'
import stone2 from '../../assets/story/stone2.png'
import map from '../../assets/how-to/map.png'
import play from '../../assets/how-to/play.png'
import earn from '../../assets/how-to/earn.png'
import earn2 from '../../assets/features/earn.gif'
import event from '../../assets/features/event.gif'
import fish from '../../assets/features/fish.gif'
import future from '../../assets/features/future.gif'
import lv from '../../assets/features/lv.gif'
import map2 from '../../assets/features/map.gif'
import marketplace from '../../assets/features/marketplace.gif'
import npc from '../../assets/features/npc.gif'
import upgrade from '../../assets/features/upgrade.gif'
function Feature(props) {
    const [scroll, setScroll] = useState(0)
    const [boatY, setBoatY] = useState(0)

    const onScroll = (event) => {
        setScroll(window.scrollY)
    }

    useEffect(() => {
        if (scroll < 1300) {
            setBoatY(0)
        } else if (scroll > 4450) {
            setBoatY(4250)
        } else {
            setBoatY(scroll - 800)
        }
        window.addEventListener('scroll', onScroll)
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    }, [scroll])

    if (props.language === 'cn') {
        return (
            <div className='detail cn'>
                <img src={island} id='island1' />
                {/* <img src={ship} id='boat' /> */}
                {/* <img src={ship} id='boat' style={{ transform: `translateY(${boatY}px)` }} /> */}
                <img
                    src={ship}
                    id='boat2'
                    style={{ transform: `translateY(${boatY}px)` }}
                />
                <div className='video cn'>
                    <iframe
                        width='1200'
                        height='675'
                        src='https://www.youtube.com/embed/K-NQ0L2_R10'
                        title='YouTube video player'
                        frameborder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        allowfullscreen
                    ></iframe>
                </div>
                <div className='whitepaper'>
                    <div className='blur'>
                        <p className='myriad'>
                            在“渔镇”，市民以捕鱼为生。这片土地含有纯净清
                            澈的水源，由名叫七海的女神所生。七海女神将水源
                            和各种鱼类视为圣物，这些鱼类养育了这座城市的许
                            多人。这个小镇的另一个奇妙之处在于，这里的水源
                            共享一种极其特殊的鱼类。拥有自己个体灵魂的鱼神
                            ——每当灵魂融合在一起时，鱼神就会为这座城市带来
                            财富。这座神圣的小镇已准备好打开大门，让每个人
                            都踏上他们的大冒险。
                            找到你自己的鱼种，在游戏中兴旺发达!!
                        </p>
                    </div>
                    <div className='flex cn'>
                        <div className='flex2 _04b cn'>
                            <a
                                className='whitepaper-btn'
                                href='https://fishingtown.io/assets/whitepaper.pdf'
                                target='_blank'
                            >
                                白皮书
                            </a>
                            <a
                                className='whitepaper-btn'
                                href='https://fishingtown.io/assets/pitchdeck.pdf'
                                target='_blank'
                            >
                                懒人包
                            </a>
                        </div>
                    </div>
                </div>
                <div className='pagebreaker'></div>

                <img src={island} id='island2' />
                <h1>怎么玩</h1>
                <div className='map retro'>
                    <img src={map} />
                    <div className='how-to earn'>
                        <a
                            href='https://fishingtown.io/assets/How%20to%20earn.pdf'
                            target='_blank'
                        >
                            <img src={earn} />
                        </a>
                        <h2>怎么赚</h2>
                    </div>
                    <div className='how-to play'>
                        <a
                            href='https://fishingtown.io/assets/Newbie%20Guide.pdf'
                            target='_blank'
                        >
                            <img src={play} />
                            <h2>玩法</h2>
                        </a>
                    </div>
                </div>
                <img src={island} id='island2' />
                <div className='features' ref={props.featuresRef}>
                    <h1 className='_04b'>功能</h1>
                    <div className='flex'>
                        <div className='box'>
                            <img src={earn2} />
                            <p>
                                玩 – 钓鱼小镇用像素游戏画面，轻松音效 <br />
                                乐 – 享受钓鱼游戏 ，游戏内各种活动，狩猎稀有鱼类
                                <br /> 赚 – 钓鱼获得奖励，收集各种鱼为NFTs
                            </p>
                        </div>
                        <div className='flex2'>
                            <div className='box2'>
                                <img src={upgrade} />
                                <p>
                                    维修鱼竿 – 保持鱼竿最佳的状态
                                    <br />
                                    食物 – 增加玩家体力
                                    <br /> 提炼 -
                                    提炼等级越高，越有机会获得稀有鱼
                                    <br /> 归并 - 合并 2 个鱼竿显着地提高容量
                                </p>
                            </div>
                            <div className='box2'>
                                <img src={lv} />
                                <p>获得更多经验来开发角色和隐藏功能</p>
                            </div>
                        </div>
                    </div>
                    <img src={island} id='island2' />
                    <div className='grid'>
                        <div className='box2'>
                            <img src={npc} />
                            <p>培养关系訪問附加功能</p>
                        </div>
                        <div className='box2'>
                            <img src={map2} />
                            <p>探索各种地方</p>
                        </div>
                        <div className='box2'>
                            <img src={fish} />
                            <p>各种鱼与季节</p>
                        </div>
                        <div className='box2'>
                            <img src={event} />
                            <p>城市节日各种精彩活动</p>
                        </div>
                        <div className='box2'>
                            <img src={marketplace} />
                            <p>市场嚷玩家交流NFTs</p>
                        </div>
                        <div className='box2'>
                            <img src={future} />
                            <p>
                                在近期功能中，将更新游戏平台，通过增加居住地和水族馆、升级房屋风格等来增强玩家的体验。那么你还在等什么
                                - 让我们一起探索这个世界从未见过的东西!
                            </p>
                        </div>
                    </div>
                    <img src={island} id='island2' id='last_island' />
                </div>
            </div>
        )
    }

    if (props.language === 'jp') {
        return (
            <div className='detail jp'>
                <img src={island} id='island1' />
                {/* <img src={ship} id='boat' /> */}
                {/* <img src={ship} id='boat' style={{ transform: `translateY(${boatY}px)` }} /> */}
                <img
                    src={ship}
                    id='boat2'
                    style={{ transform: `translateY(${boatY}px)` }}
                />
                <div className='video'>
                    <iframe
                        width='1200'
                        height='675'
                        src='https://www.youtube.com/embed/K-NQ0L2_R10'
                        title='YouTube video player'
                        frameborder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        allowfullscreen
                    ></iframe>
                </div>
                <div className='whitepaper'>
                    <div className='blur'>
                        <p className='myriad'>
                            「フィッシングタウン」で、市民は魚捕りで生活しています。
                            この陸上は
                            清明な水があるおかげで、ななみという女神を誕生しました。
                            ななみ女神は市内で多くの生活を送ってきた水源やさまざまな種類の魚を秘めています。
                        </p>
                        <p className='myriad'>
                            この町のもう一つの素晴らしい謎は、水源が1つの非常に特別な種類の魚を共有していることです。
                            独自の魂を持つ魚の神-魂が融合するたびに、魚神は都市に富をもたらします。
                        </p>
                        <p className='myriad'>
                            この神聖な町は、その門を開き、誰もが壮大な冒険に出られるようにする準備ができています。
                            あなた自身の魚種を見つけて、ゲームで繁栄してください！
                        </p>
                    </div>
                    <div className='flex'>
                        <div className='flex2 _04b'>
                            <a
                                className='whitepaper-btn'
                                href='https://fishingtown.io/assets/whitepaper.pdf'
                                target='_blank'
                            >
                                ホワイトペーパー
                            </a>
                            <a
                                className='whitepaper-btn'
                                href='https://fishingtown.io/assets/pitchdeck.pdf'
                                target='_blank'
                            >
                                ピッチデッキ
                            </a>
                        </div>
                    </div>
                </div>
                <div className='pagebreaker'></div>

                <img src={island} id='island2' />
                <h1>楽しいゲーム方法</h1>
                <div className='map retro'>
                    <img src={map} />
                    <div className='how-to earn'>
                        <a
                            href='https://fishingtown.io/assets/How%20to%20earn.pdf'
                            target='_blank'
                        >
                            <img src={earn} />
                        </a>
                        <h2>稼ぐ方法</h2>
                    </div>
                    <div className='how-to play'>
                        <a
                            href='https://fishingtown.io/assets/Newbie%20Guide.pdf'
                            target='_blank'
                        >
                            <img src={play} />
                        </a>
                        <h2>ゲームのやり方</h2>
                    </div>
                </div>
                <img src={island} id='island2' />
                <div className='features' ref={props.featuresRef}>
                    <h1 className='_04b'>フィーチャー</h1>
                    <div className='flex'>
                        <div className='box'>
                            <img src={earn2} />
                            <p>
                                やる-ピクセルアートのデザインとリラックスできるサウンドを備えた、釣りを楽しむフィッシングタウンゲーム。
                                <br />
                                楽しむ-釣りゲーム、イベント、その他のゲーム内アクティビティを楽しんだり、珍しい魚を狩ったりします。
                                <br />
                                稼ぐ-釣りや魚をNFTとして収集することで報酬を獲得できます。
                            </p>
                        </div>
                        <div className='flex2'>
                            <div className='box2'>
                                <img src={upgrade} />
                                <p>
                                    ロッドを修理します-それを再び素晴らしいものにします。
                                    <br />
                                    食べ物-プレイヤーのスタミナを増やします。
                                    <br />
                                    洗練-珍しい魚を見つける可能性を高めるために洗練。
                                    <br />
                                    合併-2本のロッドを合併して容量を大幅に強化します。
                                </p>
                            </div>
                            <div className='box2'>
                                <img src={lv} />
                                <p>
                                    キャラクターと隠された機能を開発するためのより多くの経験を積む。
                                </p>
                            </div>
                        </div>
                    </div>
                    <img src={island} id='island2' />
                    <div className='grid'>
                        <div className='box2'>
                            <img src={npc} />
                            <p>追加質にアクセスする為人間関係を作ります。</p>
                        </div>
                        <div className='box2'>
                            <img src={map2} />
                            <p>調査する為の地図と場所</p>
                        </div>
                        <div className='box2'>
                            <img src={fish} />
                            <p>色々な魚タイプと魚のシーション</p>
                        </div>
                        <div className='box2'>
                            <img src={event} />
                            <p>街のお祭りや様々なイベントを開催します。</p>
                        </div>
                        <div className='box2'>
                            <img src={marketplace} />
                            <p>プレイヤーがNFTを取引できる市場。</p>
                        </div>
                        <div className='box2'>
                            <img src={future} />
                            <p>
                                近い機能では、ゲームプラットフォームが更新され、居住地や水族館の追加、住宅スタイルのアップグレードなどにより、プレイヤーのエクスペリエンスが向上します。では、何を待っているのでしょうか。私たちと一緒に飛び乗って、世界がこれまでに見たことのないものを探索しましょう。
                            </p>
                        </div>
                    </div>
                    <img src={island} id='island2' id='last_island' />
                </div>
            </div>
        )
    }

    if(props.language==='esp') {
      return (
        <div className='detail esp'>
          <img src={island} id='island1' />
          {/* <img src={ship} id='boat' /> */}
          {/* <img src={ship} id='boat' style={{ transform: `translateY(${boatY}px)` }} /> */}
          <img
            src={ship}
            id='boat2'
            style={{ transform: `translateY(${boatY}px)` }}
          />
          <div className='video'>
            <iframe
              width='1200'
              height='675'
              src='https://www.youtube.com/embed/K-NQ0L2_R10'
              title='YouTube video player'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowfullscreen
            ></iframe>
          </div>
          <div className='whitepaper'>
            <div className='blur'>
              <p className='myriad'>
                En <strong>"Fishing Town" </strong>los ciudadanos se ganan la
                vida pescando. Esta tierra contiene agua pura y clara creada por
                una <strong>Diosa llamada Nanami.</strong> La diosa Nanami
                santifica esta fuente de agua y a diversas especies de peces que
                han alimentado a muchas vidas en la ciudad. Otro maravilloso
                misterio de este pueblo es que la fuente de agua comparte un
                tipo de pez muy especial.El Dios de los peces, el cual con su
                propia alma cada vez que se fusiona trae riquezas a la ciudad.
                Este pueblo sagrado está listo para abrir sus puertas y dejar
                que todos se embarquen en su gran aventura. Encuentra tus
                propias especies de peces y prospera con el{' '}
                <strong>juego!!.</strong>
              </p>
            </div>
            <div className='flex'>
              <div className='flex2 _04b'>
                <a
                  className='whitepaper-btn'
                  href='https://fishingtown.io/assets/whitepaper.pdf'
                  target='_blank'
                >
                  Whitepaper
                </a>
                <a
                  className='whitepaper-btn'
                  href='https://fishingtown.io/assets/pitchdeck.pdf'
                  target='_blank'
                >
                  Plataforma de lançamento
                </a>
              </div>
            </div>
          </div>
          <div className='pagebreaker'></div>

          <img src={island} id='island2' />
          <h1>Cómo divertirse</h1>
          <div className='map retro'>
            <img src={map} />
            <div className='how-to earn'>
              <a
                href='https://fishingtown.io/assets/How%20to%20earn.pdf'
                target='_blank'
              >
                <img src={earn} />
              </a>
              <h2>Cómo ganar</h2>
            </div>
            <div className='how-to play'>
              <a
                href='https://fishingtown.io/assets/Newbie%20Guide.pdf'
                target='_blank'
              >
                <img src={play} />
              </a>
              <h2>Cómo jugar</h2>
            </div>
          </div>
          <img src={island} id='island2' />
          <div className='features' ref={props.featuresRef}>
            <h1 className='_04b'>Características</h1>
            <div className='flex'>
              <div className='box'>
                <img src={earn2} />
                <p>
                  JUEGA: juego de pesca con un diseño de pixel art y un sonido
                  relajante. <br />
                  DIVERSIÓN: Disfruta del juego de pesca, los eventos, otras
                  actividades del juego y la caza de peces raros.
                  <br /> GANA:Obtén recompensas por pescar o recolectar un pez
                  como NFTS.
                </p>
              </div>
              <div className='flex2'>
                <div className='box2'>
                  <img src={upgrade} />
                  <p>
                    Repara caña: hazla genial de nuevo.
                    <br />
                    Comida: aumenta la resistencia del jugador.
                    <br /> Refinar: refinar para aumentar las posibilidades de
                    encontrar peces raros.
                    <br /> Fusión: fusiona 2 varillas para una capacidad
                    significativamente mejorada.
                  </p>
                </div>
                <div className='box2'>
                  <img src={lv} />
                  <p>
                    Ganar más experiencias para desarrollar tu personaje y
                    características ocultas.
                  </p>
                </div>
              </div>
            </div>
            <img src={island} id='island2' />
            <div className='grid'>
              <div className='box2'>
                <img src={npc} />
                <p>
                  Cultiva relaciones para acceder a características adicionales
                </p>
              </div>
              <div className='box2'>
                <img src={map2} />
                <p>Varios mapas y lugares para explorar</p>
              </div>
              <div className='box2'>
                <img src={fish} />
                <p>Varios tipos y temporadas de peces</p>
              </div>
              <div className='box2'>
                <img src={event} />
                <p>Organiza el evento de la ciudad y varios eventos más</p>
              </div>
              <div className='box2'>
                <img src={marketplace} />
                <p>Mercado donde los jugadores pueden intercambiar sus NFTs</p>
              </div>
              <div className='box2'>
                <img src={future} />
                <p>
                  En un futuro cercano, la plataforma de juego se actualizará
                  para mejorar la experiencia de los jugadores agregando
                  residencia y acuario, mejorando estilos de vivienda y mucho
                  más. Entonces, ¿qué estás esperando? ¡Sube a bordo con
                  nosotros para explorar lo que el mundo nunca ha visto!.
                </p>
              </div>
            </div>
            <img src={island} id='island2' id='last_island' />
          </div>
        </div>
      )
    }
    if(props.language==='pts') {
      return (
        <div className='detail pts'>
          <img src={island} id='island1' />
          {/* <img src={ship} id='boat' /> */}
          {/* <img src={ship} id='boat' style={{ transform: `translateY(${boatY}px)` }} /> */}
          <img
            src={ship}
            id='boat2'
            style={{ transform: `translateY(${boatY}px)` }}
          />
          <div className='video'>
            <iframe
              width='1200'
              height='675'
              src='https://www.youtube.com/embed/K-NQ0L2_R10'
              title='YouTube video player'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowfullscreen
            ></iframe>
          </div>
          <div className='whitepaper'>
            <div className='blur'>
              <p className='myriad'>
                Em <strong>" Fishing town "</strong> os cidadãos ganham a vida
                pescando peixes. Essa terra contém uma água pura e clara criada
                por uma <strong>DEUSA CHAMADA NANAMI.</strong> A Deusa Nanami
                abençoa a fonte de água e as diversas espécies de peixes que
                alimentam muitas pessoas na cidade.
              </p>
              <p className='myriad'>
                Outro maravilhoso mistério dessa cidade é que a fonte de água
                compartilha um tipo de peixe extremamente especial. O deus dos
                peixes que com sua alma individual toda vez que se funde traz
                mais riquezas para cidade
              </p>
              <p className='myriad'>
                Esta vila sagrada está pronta para abrir suas portas e deixar
                todos embarcarem em sua grande aventura. Encontre sua própria
                espécie de peixe e prospere no <strong>jogo!!.</strong>
              </p>
            </div>
            <div className='flex'>
              <div className='flex2 _04b'>
                <a
                  className='whitepaper-btn'
                  href='https://fishingtown.io/assets/whitepaper.pdf'
                  target='_blank'
                >
                  Whitepaper
                </a>
                <a
                  className='whitepaper-btn'
                  href='https://fishingtown.io/assets/pitchdeck.pdf'
                  target='_blank'
                >
                  Plataforma de lançamento
                </a>
              </div>
            </div>
          </div>
          <div className='pagebreaker'></div>

          <img src={island} id='island2' />
          <h1>Como se divertir</h1>
          <div className='map retro'>
            <img src={map} />
            <div className='how-to earn'>
              <a
                href='https://fishingtown.io/assets/How%20to%20earn.pdf'
                target='_blank'
              >
                <img src={earn} />
              </a>
              <h2>Como ganhar</h2>
            </div>
            <div className='how-to play'>
              <a
                href='https://fishingtown.io/assets/Newbie%20Guide.pdf'
                target='_blank'
              >
                <img src={play} />
              </a>
              <h2>Como jogar</h2>
            </div>
          </div>
          <img src={island} id='island2' />
          <div className='features' ref={props.featuresRef}>
            <h1 className='_04b'>Características</h1>
            <div className='flex'>
              <div className='box'>
                <img src={earn2} />
                <p>
                  Jogue: Jogo de pesca com desenho de pixel art e sons
                  relaxantes. <br />
                  Diversão: Curta a pescaria no jogo, eventos, outras ações no
                  jogo e a caçada de peixes raros
                  <br /> Ganhe: Ganhe recompensas por pescar ou coletar os
                  peixes NFTs
                </p>
              </div>
              <div className='flex2'>
                <div className='box2'>
                  <img src={upgrade} />
                  <p>
                    Reparar a vara: Faça sua durabilidade voltar ao máximo.
                    <br />
                    Comida: Aumenta a stamina do jogador
                    <br /> Refino: Aumenta a chance de achar peixes mais raros
                    <br /> Fusão: Faça fusão de 2 varas para ter uma capacidade
                    significamente melhorada
                  </p>
                </div>
                <div className='box2'>
                  <img src={lv} />
                  <p>
                    Ganhe mais experiência para desenvolver seu personagem e
                    desbloquear características escondidas
                  </p>
                </div>
              </div>
            </div>
            <img src={island} id='island2' />
            <div className='grid'>
              <div className='box2'>
                <img src={npc} />
                <p>
                  Construa relações para ter acesso a características adicionais
                </p>
              </div>
              <div className='box2'>
                <img src={map2} />
                <p>Varios mapas e lugares para explorar </p>
              </div>
              <div className='box2'>
                <img src={fish} />
                <p>Varios tipos e temporadas de peixes</p>
              </div>
              <div className='box2'>
                <img src={event} />
                <p>Organizar eventos na cidade e vários outros eventos </p>
              </div>
              <div className='box2'>
                <img src={marketplace} />
                <p>Mercado onde os jogadores podem vender suas NFTs</p>
              </div>
              <div className='box2'>
                <img src={future} />
                <p>
                  Em um futuro próximo a plataforma do jogo será atualizada para
                  melhorar a experiência do usuário adicionando residência e
                  aquário, dando upgrade em estilos de casas e muito mais.
                  Então, o que está esperando? suba a bordo conosco para
                  explorar o que o mundo nunca viu!
                </p>
              </div>
            </div>
            <img src={island} id='island2' id='last_island' />
          </div>
        </div>
      )
    }
    else {
      return (
        <div className='detail '>
          <img src={island} id='island1' />
          {/* <img src={ship} id='boat' /> */}
          {/* <img src={ship} id='boat' style={{ transform: `translateY(${boatY}px)` }} /> */}
          <img
            src={ship}
            id='boat2'
            style={{ transform: `translateY(${boatY}px)` }}
          />
          <div className='video'>
            <iframe
              width='1200'
              height='675'
              src='https://www.youtube.com/embed/K-NQ0L2_R10'
              title='YouTube video player'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowfullscreen
            ></iframe>
          </div>
          <div className='whitepaper'>
            <div className='blur'>
              <p className='myriad'>
                At <strong>"Fishing Town"</strong> citizens earn a living
                through catching fishes. This land contains pure and clear
                origin of water that was given birth by a.....{' '}
                <strong>Goddess named Nanami.</strong> Goddess Nanami hallows
                water source and various breed of fishes which have fed many
                lives in the city.{' '}
              </p>
              <p className='myriad'>
                Another wonderful mystery of this town is that the water source
                shares one extremely special type of fish. The God of fish with
                its own individual soul - whenever the soul merges together, the
                God of fish bring wealth to the city. This sacred town is ready
                to open its gate and let everyone be on their grand adventure.
                Find your own fish species and be prosperous with the{' '}
                <strong>game!!</strong>
              </p>
            </div>
            <div className='flex'>
              <div className='flex2 _04b'>
                <a
                  className='whitepaper-btn'
                  href='https://fishingtown.io/assets/whitepaper.pdf'
                  target='_blank'
                >
                  White Paper
                </a>
                <a
                  className='whitepaper-btn'
                  href='https://fishingtown.io/assets/pitchdeck.pdf'
                  target='_blank'
                >
                  Pitch Deck
                </a>
              </div>
            </div>
          </div>
          <div className='pagebreaker'></div>

          <img src={island} id='island2' />
          <h1>HOW TO FUN</h1>
          <div className='map retro'>
            <img src={map} />
            <div className='how-to earn'>
              <a
                href='https://fishingtown.io/assets/How%20to%20earn.pdf'
                target='_blank'
              >
                <img src={earn} />
              </a>
              <h2>How to earn</h2>
            </div>
            <div className='how-to play'>
              <a
                href='https://fishingtown.io/assets/Newbie%20Guide.pdf'
                target='_blank'
              >
                <img src={play} />
              </a>
              <h2>How to play</h2>
            </div>
          </div>
          <img src={island} id='island2' />
          <div className='features' ref={props.featuresRef}>
            <h1 className='_04b'>Features</h1>
            <div className='flex'>
              <div className='box'>
                <img src={earn2} />
                <p>
                  PLAY - fishing town game that came with pixel-art design and
                  relaxing sound. <br />
                  FUN - enjoy the fishing game, events, other in-game activities
                  and hunting the rare fishes.
                  <br /> Earn - get reward from fishing or collecting a fish as
                  NFTs.
                </p>
              </div>
              <div className='flex2'>
                <div className='box2'>
                  <img src={upgrade} />
                  <p>
                    Repair rod – make it great again.
                    <br />
                    Food – increase player’s Stamina.
                    <br /> Refine – refining to upgrade more chance of finding
                    the rare fishes.
                    <br /> Merger – merge 2 rods for significantly enhanced
                    capacity
                  </p>
                </div>
                <div className='box2'>
                  <img src={lv} />
                  <p>
                    gain more experiences to develop a character and hidden
                    feature.
                  </p>
                </div>
              </div>
            </div>
            <img src={island} id='island2' />
            <div className='grid'>
              <div className='box2'>
                <img src={npc} />
                <p>
                  cultivate relationship for accessing to additional features.
                </p>
              </div>
              <div className='box2'>
                <img src={map2} />
                <p>various maps and places to explore.</p>
              </div>
              <div className='box2'>
                <img src={fish} />
                <p>various types and seasons of fishes.</p>
              </div>
              <div className='box2'>
                <img src={event} />
                <p>Organize the festivals of the city and various events.</p>
              </div>
              <div className='box2'>
                <img src={marketplace} />
                <p>market where players can trade NFTs.</p>
              </div>
              <div className='box2'>
                <img src={future} />
                <p>
                  in the near future, gaming platforms will be updated to
                  enhance players’ experience by adding residency and aquarium,
                  upgrading housing styles or much more. So what are you waiting
                  for – let’s hop on board with us to explore what the world has
                  never seen!
                </p>
              </div>
            </div>
            <img src={island} id='island2' id='last_island' />
          </div>
        </div>
      )
    }
}

export default Feature
